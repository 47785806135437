import React, { Dispatch, SetStateAction } from 'react';
import Text from '@uicl/ui-core/dist/Text';
import Input from '@uicl/ui-core/dist/Input';
import DatePicker from '@uicl/ui-core/dist/DatePicker';
import { Button, ButtonGroup } from '@uicl/ui-core/dist';
import {
  DateFilter,
  IEraSearchRequestBody,
} from '../../api/EraSearch/interfaces';
import moment from 'moment';

interface IProps {
  eraSearchRequestBody: IEraSearchRequestBody;
  handleOnReset: () => void;
  handleOnSearch: () => void;
  handleOnChange: (e?: React.ChangeEvent<HTMLInputElement>, dateFilterOption?: DateFilter) => void;
}

const SearchComponent: React.FC<IProps> = ({ eraSearchRequestBody, handleOnSearch, handleOnReset, handleOnChange }) => {
  return (
    <div data-testid="era-search-component-testid">
      {/*@ts-ignore*/}
      <Text> Enter ERA Details</Text>
      <div style={{ marginLeft: 30, display: 'flex', marginTop: 15 }}>
        <Input
          aria-label="ARIA Label"
          autoComplete="off"
          dataTestId="payerId"
          domID="payerId"
          placeholder="Payer ID "
          label="Payer ID"
          size="medium"
          name="payerId"
          value={eraSearchRequestBody.payerId}
          onChange={(e) => handleOnChange(e)}
          maxLength={10}
          errorMessage="Invalid Payer ID"
        />
      </div>

      <div style={{ marginLeft: 15, display: 'flex', marginTop: 10 }}>
        <div style={{ marginLeft: 15, display: 'flex' }}>
          <Input
            aria-label="ARIA Label"
            autoComplete="off"
            dataTestId="checkEftNo"
            domID="eftNo-id"
            placeholder="Check/EFT No."
            label="Check/EFT No."
            size="medium"
            value={eraSearchRequestBody.checkEftNumber}
            name="checkEftNumber"
            onChange={(e) => handleOnChange(e)}
            maxLength={50}
            errorMessage="Invalid Check/EFT No."
          />
        </div>
        <div style={{ marginLeft: 15, display: 'flex' }}>
          <Input
            aria-label="ARIA Label"
            autoComplete="off"
            dataTestId="checkEftAmt"
            domID="eftAmt-id"
            placeholder="Check/EFT Amt."
            label="Check/EFT Amt."
            size="medium"
            value={eraSearchRequestBody.checkEftAmount ? eraSearchRequestBody.checkEftAmount : ''}
            name="checkEftAmount"
            onChange={(e) => handleOnChange(e)}
            maxLength={18}
            errorMessage="Invalid Check/EFT Amt."
          />
        </div>
        <div style={{ marginLeft: 15, display: 'flex' }}>
          <Input
            aria-label="ARIA Label"
            autoComplete="off"
            dataTestId="insuredId"
            domID="insuredId-id"
            placeholder="Insured ID"
            label="Insured ID "
            size="medium"
            value={eraSearchRequestBody.insuredId}
            name="insuredId"
            onChange={(e) => handleOnChange(e)}
            maxLength={80}
            errorMessage="Invalid Insured ID"
          />
        </div>
      </div>

      <div style={{ marginLeft: 15, display: 'flex', marginTop: 10 }}>
        <div style={{ marginLeft: 15, display: 'flex' }}>
          <Input
            aria-label="ARIA Label"
            autoComplete="off"
            dataTestId="taxId"
            domID="taxId-id"
            placeholder="Tax ID"
            label="Tax ID"
            size="medium"
            value={eraSearchRequestBody.taxId}
            name="taxId"
            onChange={(e) => handleOnChange(e)}
            errorMessage="Invalid Tax ID"
            maxLength={9}
          />
        </div>
        <div style={{ marginLeft: 15, display: 'flex' }}>
          <Input
            aria-label="ARIA Label"
            autoComplete="off"
            dataTestId="billingNpi"
            domID="billingNpi-id"
            placeholder="Billing NPI"
            label="Billing NPI"
            size="medium"
            value={eraSearchRequestBody.billingNpi}
            name="billingNpi"
            onChange={(e) => handleOnChange(e)}
            errorMessage="Invalid Billing NPI"
            maxLength={10}
          />
        </div>
        <div style={{ marginLeft: 15, display: 'flex' }}>
          <Input
            aria-label="ARIA Label"
            autoComplete="off"
            dataTestId="transactionId"
            domID="transactionId-id"
            placeholder="Transaction ID "
            label="Transaction ID  "
            size="medium"
            value={eraSearchRequestBody.transactionId}
            name="transactionId"
            onChange={(e) => handleOnChange(e)}
            maxLength={15}
            errorMessage="Invalid Transaction ID"
          />
        </div>
      </div>

      <div style={{ marginLeft: 15, display: 'flex', marginTop: 10 }}>
        <div style={{ marginLeft: 15, display: 'flex' }}>
          <DatePicker
            className=""
            initialSelection={moment(eraSearchRequestBody.dateRangeFilter.startDate)}
            dataTestId="start-date-era"
            domID="start-date-era"
            errorMessage="Invalid Date"
            calendarView="day"
            label="Check/EFT Start Date"
            openDirection="down"
            showCalendarIcon
            size="medium"
            onDateChange={(e) => {
              const event = { target: { value: e } };
              if (event.target.value != null) {
                const startDt = event.target.value.format('YYYY-MM-DD').toString();
                eraSearchRequestBody.dateRangeFilter.startDate = event.target.value ? startDt : '';
              }
            }}
          />
        </div>
        <div style={{ marginLeft: 15, display: 'flex' }}>
          <DatePicker
            calendarView="day"
            className=""
            dataTestId="end-date-era"
            domID="end-date-era"
            errorMessage="Invalid Date"
            label="Check/EFT End Date"
            openDirection="down"
            showCalendarIcon
            size="medium"
            initialSelection={moment(eraSearchRequestBody.dateRangeFilter.endDate)}
            onDateChange={(e) => {
              const event = { target: { value: e } };
              if (event.target.value != null) {
                const endDt = event.target.value.format('YYYY-MM-DD').toString();
                eraSearchRequestBody.dateRangeFilter.endDate = event.target.value ? endDt : '';
              }
            }}
          />
        </div>
      </div>

      <div style={{ marginLeft: 25, display: 'flex', marginTop: 50 }}>
        <ButtonGroup>
          <Button
            dataTestId="era-search-button"
            domID="automation-id"
            name="Submit"
            buttonType="emphasized"
            size="medium"
            type="submit"
            onClick={handleOnSearch}
          />

          <Button
            dataTestId="era-reset-button"
            domID="automation-id"
            name="Reset"
            buttonType="unstyled"
            size="medium"
            type="reset"
            onClick={handleOnReset}
          />
        </ButtonGroup>
      </div>
    </div>
  );
};

export default SearchComponent;

import Chip from '@uicl/ui-core/dist/Chip';
import React from 'react';
import { ChipTypeProp } from '@uicl/ui-core/types/Atoms/Chip';

export enum EClaimStatus {
  AcceptedByPayer = 'AcceptedByPayer',
  RejectedByPayer = 'RejectedByPayer',
  DeniedByPayer = 'DeniedByPayer',
  SubmittedToClearinghouse = 'SubmittedToClearinghouse',
  AcceptedByClearinghouse = 'AcceptedByClearinghouse',
  RejectedByClearinghouse = 'RejectedByClearinghouse',
  Unknown = 'Unknown',
}

const ClaimStatusMap = new Map<EClaimStatus, { label: string; type: ChipTypeProp }>();
ClaimStatusMap.set(EClaimStatus.SubmittedToClearinghouse, { label: 'Submitted to Clearinghouse', type: 'neutral' });
ClaimStatusMap.set(EClaimStatus.AcceptedByClearinghouse, { label: 'Accepted by Clearinghouse', type: 'positive' });
ClaimStatusMap.set(EClaimStatus.RejectedByClearinghouse, { label: 'Rejected by Clearinghouse', type: 'negative' });
ClaimStatusMap.set(EClaimStatus.AcceptedByPayer, { label: 'Accepted by Payer', type: 'positive' });
ClaimStatusMap.set(EClaimStatus.DeniedByPayer, { label: 'Denied by Payer', type: 'negative' });
ClaimStatusMap.set(EClaimStatus.RejectedByPayer, { label: 'Rejected by Payer', type: 'negative' });
ClaimStatusMap.set(EClaimStatus.Unknown, { label: 'Unknown', type: 'dark' });

interface ClaimStatusProps {
  claimStatus: EClaimStatus | string;
}

const ClaimStatus: React.FC<ClaimStatusProps> = ({ claimStatus }) => {
  const mappedStatus = ClaimStatusMap.get(claimStatus as EClaimStatus);
  const label = mappedStatus?.label ? mappedStatus.label : EClaimStatus.Unknown;
  const type = mappedStatus?.type ? mappedStatus.type : 'dark';

  return <Chip label={label} type={type} />;
};

export default ClaimStatus;

import React, { useCallback, useEffect, useState, memo, SyntheticEvent } from 'react';
import styled from 'styled-components';
import Text from '@uicl/ui-core/dist/Text';
import Button from '@uicl/ui-core/dist/Button';
import Previous from '@uicl/ui-core/dist/Previous';
import Next from '@uicl/ui-core/dist/Next';
import Input from '@uicl/ui-core/dist/Input';
import './Claim.css';
import TableData from '../../components/Claims/TableData';
import DateRangePicker from '@uicl/ui-core/dist/DateRangePicker';
import moment from 'moment';
import { IClaimViewResponse } from '../../api/claimView/interface';
import { useAppDispatch, useAppSelector } from '../../reduxStore/reduxHooks';
import { handleClaimSearchApi } from '../../api/claimSearch';
import { ESortingDirection, TSortingField } from './interface';
import { EClaimSearchDrawerMode, IClaimSearch } from '../../api/claimSearch/interface';
import { IPagingOptions } from '../../allOtherInterfaces/pagingOptionInterface';
import { setAppError } from '../../reduxStore/authSlice';
import { regex } from '../../utils/constants';
import { handleDocumentBodyScroll } from '../../utils/constants';

const limit = 30;
const StyledDiv = styled.div`
  height: 100vh;
  padding: 1rem;
`;

const StyledBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Claims = () => {
  const { legalentityData } = useAppSelector((state) => state.legalentitySlice);
  const [startDate, setStartDate] = useState<moment.Moment | undefined>(moment(new Date()).subtract(6, 'days'));
  const [endDate, setEndDate] = useState<moment.Moment | undefined>(moment(startDate).add(6, 'days'));
  const [selectedDate, setSelectedDate] = useState();
  const [hasError, setHasError] = useState(true);
  const [hasDateError, setHasDateError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [openClaimDetail, setOpenClaimDetail] = React.useState(false);
  const [claimId, setClaimId] = useState<string>('');

  const [claimViewData, setClaimViewData] = React.useState<IClaimViewResponse>({} as IClaimViewResponse);
  const [legalEntityId, setLegalEntityId] = useState<string | null>(null);
  const [taxId, setTaxId] = useState<string | null>(null);

  const [page, setPage] = useState(1);
  const [sortingDirection, setSortingDirection] = useState<ESortingDirection>(ESortingDirection.DESCENDING);
  const [sortingFieldArray, setSortingFieldArray] = useState<TSortingField[]>([]);
  const [claimSearchData, setClaimSearchData] = useState<IClaimSearch[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pagingOptions, setPagingOptions] = useState<IPagingOptions>({} as IPagingOptions);
  const [selectedClaim, setSelectedClaim] = React.useState<IClaimSearch>();
  const [claimDrawerMode, setClaimDrawerMode] = React.useState<EClaimSearchDrawerMode>(
    EClaimSearchDrawerMode.VIEWCLAIM
  );
  const dispatch = useAppDispatch();

  const onClickThrough = (e: SyntheticEvent, row: any) => {
    e.stopPropagation();
    handleDocumentBodyScroll(false);
    setOpenClaimDetail(true);
    setClaimId(row.id);
    setClaimDrawerMode(EClaimSearchDrawerMode.VIEWCLAIM);
  };

  const onAttachmentClickThrough = (e: SyntheticEvent, row: any) => {
    e.stopPropagation();
    handleDocumentBodyScroll(false);
    setOpenClaimDetail(true);
    setClaimId(row.id);
    setClaimDrawerMode(EClaimSearchDrawerMode.VIEWATTACHMENT);
  };

  const onCloseDrawer = () => {
    handleDocumentBodyScroll(true);
    setOpenClaimDetail(false);
  };

  const handleOnClaimSearch = async () => {
    try {
      if (!hasError) {
        const offset = (page - 1) * limit;
        const requestBody = {
          legalEntityId: taxId ?? "",
          dateRangeFilter: {
            field: 'ProcessedOn',
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD'),
          },
          sorting: {
            direction: sortingDirection,
            fields: sortingFieldArray,
          },
          offset,
          limit,
        };

        const response = await handleClaimSearchApi(requestBody, dispatch);
        const newMap = response.results.map((item, index) => {
          return {
            ...item,
            dateOfService: moment(item.dateOfService).format('MM/DD/YYYY'),
            processedOn: moment(item.processedOn).format('MM/DD/YYYY'),
            amount: `${item.amount}`,
            status: item.status,
          };
        });

        setClaimSearchData(newMap);
        setTotalCount(response.totalCount);
        setPagingOptions(response.pagingOptions);
      }
    } catch (err) {
      const error = err as Error;
      //TODO: https://jira.healthcareit.net/browse/DCP2-7345 proper handling of error in catch block
      dispatch(setAppError({ message: error.message, isOpen: true }));
    }
  };

  useEffect(() => {
    if (legalentityData.length > 0) {
      handleOnClaimSearch();
    }
  }, [legalentityData, sortingDirection, sortingFieldArray, page, claimDrawerMode, setClaimDrawerMode]);

  // only for disabling the search button
  useEffect(() => {
    if (!taxId || !taxId.match(regex.legalEntityTaxId)) {
      setHasError(true);
      return;
    } else if (!startDate || !endDate) {
      setHasError(true);
      setErrorMessage('Start and end date should be a max of 7 days');
      setHasDateError(true);
      return;
    } else if (endDate) {
      const start = moment(startDate);
      const end = moment(endDate);
      if (end.diff(start, 'days') > 6) {
        setHasError(true);
        setErrorMessage('Start and end date should be a max of 7 days');
        setHasDateError(true);
        return;
      }
    }

    setHasError(false);
    setHasDateError(false);
    setErrorMessage('');
  }, [taxId, startDate, endDate]);

  const handlePageNext = () => {
    setPage((prev) => prev + 1);
  };

  const handlePagePrevious = () => {
    /* istanbul ignore next */
    setPage((prev) => prev - 1);
  };
  const renderHeader = () => (
    <StyledBox>
      <div style={{ marginTop: 40 }}>
        <Text variant="large">Claim Search</Text>
      </div>
    </StyledBox>
  );

  const renderButtonSection = () => (
    <StyledBox>
      <div style={{ display: 'flex' }}>
        <div style={{ marginLeft: 15, display: 'flex', marginTop: 10 }}>
          <Input
            aria-label="ARIA Label"
            autoComplete="off"
            dataTestId="taxId"
            domID="taxId"
            placeholder="Tax ID"
            label="Tax ID"
            size="medium"
            name="taxId"
            onChange={(e) => setTaxId(e.target.value)}
            maxLength={11}
            errorMessage="Invalid Tax ID"
            regex={regex.legalEntityTaxId}
            hasError={hasError}
          />
          <DateRangePicker
            dataTestId="test-dateRangePicker"
            domID="test-dateRangePicker"
            errorMessage={errorMessage}
            minimumNights={1}
            numberOfMonths={1}
            openDirection="down"
            showCalendarIcon
            initialStartDate={startDate}
            initialEndDate={endDate}
            size="medium"
            maxDate={moment()}
            label="Processed On"
            onStartInputChange={(date) => {
              //TODO https://jira.healthcareit.net/browse/DCP2-6995
              /* istanbul ignore next */
              setSelectedDate(date as any);
            }}
            onDatesChange={(date) => {
              setStartDate(moment(date.startDate ?? undefined));
              setEndDate(moment(date.endDate ?? undefined));
            }}
            hasError={hasDateError}
          />

          <div style={{ marginLeft: 5, marginTop: 20 }}>
            <Button
              buttonType="standard"
              dataTestId={'apply-search'}
              domID={'apply-arrow'}
              size="medium"
              type="button"
              name="Search"
              onClick={handleOnClaimSearch}
              disabled={hasError}
            />
          </div>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <Text variant="small" className="pagination-text-color">
          {pagingOptions.offset} of {totalCount}
        </Text>
        <div style={{ display: 'flex' }}>
          <Button
            buttonType="deEmphasized"
            dataTestId={'left-arrow'}
            domID={'left-arrow'}
            icon={Previous}
            size="small"
            type="button"
            className="action-buttons"
            onClick={handlePagePrevious}
            disabled={page === 1 ? true : false}
          />
          <Button
            buttonType="deEmphasized"
            dataTestId={'right-arrow'}
            domID={'right-arrow'}
            icon={Next}
            size="small"
            type="button"
            className="action-buttons"
            disabled={pagingOptions.morePages ? false : true}
            onClick={handlePageNext}
          />
        </div>
      </div>
    </StyledBox>
  );

  const renderClaims = () => (
    <>
      {renderButtonSection()}
      <div style={{ marginTop: 20 }}>
        <TableData
          openClaimDetail={openClaimDetail}
          onClickThrough={onClickThrough}
          onCloseDrawer={onCloseDrawer}
          claimViewData={claimViewData}
          setSortingDirection={setSortingDirection}
          setSortingFieldArray={setSortingFieldArray}
          claimSearchData={claimSearchData}
          sortingFieldArray={sortingFieldArray}
          claimId={claimId}
          setClaimViewData={setClaimViewData}
          selectedClaim={selectedClaim}
          setSelectedClaim={setSelectedClaim}
          claimDrawerMode={claimDrawerMode}
          setClaimDrawerMode={setClaimDrawerMode}
          onAttachmentClickThrough={onAttachmentClickThrough}
        />
      </div>
    </>
  );

  return (
    <StyledDiv data-testid="claimsPage-testid">
      <div style={{ display: 'flex' }}>
        <div style={{ width: '100px' }} />
        <div style={{ width: '100%' }}>
          {renderHeader()}
          <hr />
          {renderClaims()}
        </div>
      </div>
    </StyledDiv>
  );
};

export default memo(Claims);

import React from 'react';
import Grid from '@uicl/ui-core/dist/Grid';
import { accountDetailColumn } from './AccountSelectionColumns';
import { IAdminAccountDetail } from '../../api/user/interface';
import { Button, DotsFilled } from '@uicl/ui-core/dist';

interface IProps {
  accountList: IAdminAccountDetail[];
  handleUseAccount: (adminAccountId: string) => void;
}

const AccountSelectionTableData: React.FC<IProps> = ({ accountList, handleUseAccount }) => {
  const handleMapping = () =>
    accountList.map((item, index) => {
      return {
        ...item,
        actions: {
          domID: 'account-selection-action-' + index.toString(),
          dataTestId: 'account-selection-action-' + index.toString(),
          label: 'Actions',
          name: 'Use this account',
          type: 'button',
          buttonType: 'deEmphasized',
          onClick: () => {
            handleUseAccount(item.adminAccountId as string);
          },
        },
      };
    });

  return (
    <div>
      <Grid
        dataTestId="account-selection-grid"
        domID="account-selection-grid"
        isConfigurable={false}
        records={handleMapping()}
        selectionKey="text"
        supportSelection={false}
        columns={accountDetailColumn}
        maxHeight="500px"
        isFixedHeader={true}
      />
    </div>
  );
};

export default AccountSelectionTableData;

import React, { Dispatch, memo, SyntheticEvent, useEffect, useState } from 'react';
import Grid from '@uicl/ui-core/dist/Grid';
import { IEligibilityFrequencyLimitationsResponse } from '../../../api/HistoricalTransactionDetailView/interfaces';
import {mockFrequencyLimitationsResponse} from '../../../api/HistoricalTransactionDetailView/mockResponse';
import { historicalFrequencyLimitationsTableColumns } from './HistoricalGridDetailsTableColumns';

interface IProps {
    historicalFrequencyLimitationsResult: IEligibilityFrequencyLimitationsResponse;
}

const HistoricalFrequencyLimitationsDetailData: React.FC<IProps> = () => {
  const handleMapping = () =>
    mockFrequencyLimitationsResponse.frequencyLimitations.map((item) => {
      return {
        procedureCode: item.procedureCode,
        plan: item.plan,
        restriction: item.restriction,
        lastVisit: item.lastVisit,
    };
    });

  return (
    <div style={{ marginTop: 20, paddingBottom: 50, paddingLeft:50, paddingRight:20}}>
      <Grid
        dataTestId="eligibility-frequencylimitationsdetail-grid"
        domID="eligibility-frequencylimitations-grid"
        isConfigurable={false}
        records={handleMapping()}
        selectAllCheckboxDomID="select-all-test-id"
        selectionKey="text"
        supportSelection={false}
        columns={historicalFrequencyLimitationsTableColumns}
        maxHeight="500px"
        isFixedHeader={true}
      />
    </div>
  );
};

export default memo(HistoricalFrequencyLimitationsDetailData);

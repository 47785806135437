import React, { ReactNode } from 'react';
import { Button, ButtonGroup,IconButton } from '@uicl/ui-core/dist';
import { DrawerBox, DrawerContatiner, DrawerContent, DrawerDisplayContent } from './styles';
interface PopupProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  children: ReactNode;
}

const EraView835Modal: React.FC<PopupProps> = ({ isOpen, onClose, onSave, children }) => {
  if (!isOpen) {
    return null;
  }
  return (
    <DrawerBox data-testid="drawerContainer" id="drawerContainer">
      <DrawerContatiner>
        <DrawerContent percentageView={true} containerWidth={'70vw'}>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
           <ButtonGroup>
              <Button dataTestId='drawer_btn_save' buttonType="emphasized" onClick={onSave}>
                Save
              </Button>
              <Button dataTestId='drawer_btn_close' buttonType="emphasized" onClick={onClose}>
                Close
              </Button>
            </ButtonGroup>           
          </div>
          <DrawerDisplayContent>
          {children}
          </DrawerDisplayContent>          
        </DrawerContent>
      </DrawerContatiner>
    </DrawerBox>
  );
};

export default EraView835Modal;
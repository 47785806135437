import { apiInstance } from '../apiInstance';
import { IUserAccountInfo } from './interface';

export const getUserAccountInfo = (dispatch?: any): Promise<IUserAccountInfo> => {
  return new Promise((resolve, reject) => {
    apiInstance(dispatch)
      .get('/user/me')
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

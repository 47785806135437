import React from 'react';
import { InsuredStyledDiv, ClaimGridDiv, ClaimGridCenterDiv } from '../styles';
import Text from '@uicl/ui-core/dist/Text';
import { IClaimViewResponse } from '../../../api/claimView/interface';
import moment from 'moment';
import ClaimStatus from '../../Claims/ClaimStatus';

interface IProps {
  claimViewData: IClaimViewResponse;
}

const ClaimStatusComponent: React.FC<IProps> = ({ claimViewData }) => {
  return (
    <div>
      <div style={{ borderBottom: '2px solid #e0e0e0' }} data-testid="claimStatusContainer">
        <InsuredStyledDiv>
          <ClaimGridDiv>
            <Text color="secondary" variant="small">
              Payer
            </Text>
            <Text>{claimViewData.payerInfo.name}</Text>
          </ClaimGridDiv>
          <ClaimGridDiv>
            <ClaimGridCenterDiv>
              <Text color="secondary" variant="small">
                Rendering Provider
              </Text>
              <Text>
                {claimViewData.providerInfo.renderingProvider.name.firstName}
                {claimViewData.providerInfo.renderingProvider.name.lastName}
              </Text>
            </ClaimGridCenterDiv>
          </ClaimGridDiv>
          <ClaimGridDiv>
            <Text color="secondary" variant="small">
              Relationship
            </Text>
            <Text>{claimViewData.patientInfo.relationship}</Text>
          </ClaimGridDiv>
        </InsuredStyledDiv>
        <InsuredStyledDiv>
          <ClaimGridDiv>
            <Text color="secondary" variant="small">
              Insured Name
            </Text>
            <Text>
              {claimViewData.primarySubscriberInfo.name.firstName}
              {claimViewData.primarySubscriberInfo.name.lastName}
            </Text>
          </ClaimGridDiv>
          <ClaimGridDiv>
            <ClaimGridCenterDiv>
              <Text color="secondary" variant="small">
                Change HealthCare Claim ID
              </Text>
              <Text>{claimViewData.claimId}</Text>
            </ClaimGridCenterDiv>
          </ClaimGridDiv>
          <ClaimGridDiv>
            <Text color="secondary" variant="small">
              Patient Gender
            </Text>
            <Text>{claimViewData.patientInfo.genderCode}</Text>
          </ClaimGridDiv>
        </InsuredStyledDiv>
        <InsuredStyledDiv>
          <ClaimGridDiv>
            <Text color="secondary" variant="small">
              Patient Name
            </Text>
            <Text>
              {claimViewData.patientInfo.name.firstName}
              {claimViewData.patientInfo.name.lastName}
            </Text>
          </ClaimGridDiv>
          <ClaimGridDiv>
            <ClaimGridCenterDiv>
              <Text color="secondary" variant="small">
                Payer Control No.
              </Text>
              <Text>{claimViewData.payerControlNumber}</Text>
            </ClaimGridCenterDiv>
          </ClaimGridDiv>
          <ClaimGridDiv>
            <ClaimGridDiv>
              <Text color="secondary" variant="small">
                Charge Amount
              </Text>
              <Text>${claimViewData.claimAmount}</Text>
            </ClaimGridDiv>
          </ClaimGridDiv>
        </InsuredStyledDiv>
        <InsuredStyledDiv>
          <ClaimGridDiv>
            <Text color="secondary" variant="small">
              Patient DOB
            </Text>
            <Text>{moment(claimViewData.patientInfo.dateOfBirth).format('M/DD/YYYY')}</Text>
          </ClaimGridDiv>
          <ClaimGridDiv>
            <ClaimGridCenterDiv>
              <Text color="secondary" variant="small">
                Insured ID
              </Text>
              <Text>{claimViewData.primarySubscriberInfo.memberId}</Text>
            </ClaimGridCenterDiv>
          </ClaimGridDiv>
          <ClaimGridDiv>
            <ClaimGridDiv>
              <Text color="secondary" variant="small">
                Service Date
              </Text>
              <Text>{moment(claimViewData.serviceDate).format('M/DD/YYYY')} </Text>
            </ClaimGridDiv>
          </ClaimGridDiv>
        </InsuredStyledDiv>
      </div>
      <div data-testid="claimStatusTreatmentContainer">
        <div style={{ marginBottom: 10, marginTop: 10 }}>
          <Text variant="large">Status</Text>
        </div>
        <div style={{ marginTop: 10 }}>
          <InsuredStyledDiv>
            <ClaimGridDiv>
              <Text color="secondary" variant="small">
                Status Date
              </Text>
              <Text>{moment(claimViewData.statusDate).format('M/DD/YYYY')}</Text>
            </ClaimGridDiv>
            <ClaimGridDiv>
              <ClaimGridCenterDiv>
                <Text color="secondary" variant="small">
                  Status
                </Text>
                <ClaimStatus claimStatus={claimViewData.status} />
              </ClaimGridCenterDiv>
            </ClaimGridDiv>
            <ClaimGridDiv />
          </InsuredStyledDiv>
        </div>
      </div>
    </div>
  );
};

export default ClaimStatusComponent;

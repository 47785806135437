import React from 'react';
import AttachmentOverview from '../../UnSubmittedClaims/AttachmentDetail/AttachmentOverview';
import Text from '@uicl/ui-core/dist/Text';
import { IClaimViewResponse } from '../../../api/claimView/interface';
import { IAttachmentFileInfo } from '../../../api/attachments/interface';

interface IProps {
  claimViewResponseData?: IClaimViewResponse;
  attachmentData: IAttachmentFileInfo[];
}

const inputArray = (input?: IClaimViewResponse) => {
  return [
    {
      label: 'Patient Name',
      domID: 'patient-name',
      initialValue: input?.patientInfo?.name?.firstName + ' ' + input?.patientInfo?.name?.lastName,
      width: '40%',
      marginBottom: 20,
      key: 'addressLine1',
    },
    {
      label: 'Claim Amount',
      domID: 'claim-amount',
      initialValue: input?.claimAmount,
      width: '40%',
      marginBottom: 20,
      key: 'addressLine2',
    },
    {
      label: 'Insured Id',
      domID: 'insured-id',
      initialValue: input?.primarySubscriberInfo?.memberId,
      width: '40%',
      marginBottom: 20,
      key: 'city',
    },
    {
      label: 'NPI',
      domID: 'npi',
      initialValue: input?.providerInfo?.renderingProvider?.npi,
      width: '40%',
      marginBottom: 20,
      key: 'city',
    },
    {
      label: 'Payer',
      domID: 'payer',
      initialValue: input?.payerInfo?.name,
      width: '40%',
      marginBottom: 20,
      key: 'city',
    },
    {
      label: 'Tax Id',
      domID: 'tax-id',
      initialValue: input?.providerInfo?.billingProvider?.taxId,
      width: '40%',
      marginBottom: 20,
      key: 'city',
    },
    {
      label: 'Date of Service',
      domID: 'date-of-service',
      initialValue: input?.serviceDate,
      width: '40%',
      marginBottom: 20,
      key: 'city',
    },
  ];
};

const ViewAttachments: React.FC<IProps> = ({ claimViewResponseData, attachmentData }) => {
  const renderInputSection = () =>
    inputArray(claimViewResponseData).map((item) => (
      <div style={{ width: item.width, marginBottom: item.marginBottom }}>
        <Text color="secondary" variant="small">
          {item.label}
        </Text>

        <Text>{item.initialValue}</Text>
      </div>
    ));

  return (
    <div>
      <div style={{ marginBottom: 30 }}>
        <Text variant="large">Claim Treatments</Text>
      </div>
      <div style={{ width: '70%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        {renderInputSection()}
      </div>
      <div data-testid="attachment-drawer">
        <div style={{ marginTop: 30, marginBottom: 30 }}>
          <Text variant="large">Attachment Overview</Text>
        </div>
        <AttachmentOverview getAttachmentsData={() => attachmentData}></AttachmentOverview>
      </div>
    </div>
  );
};
export default ViewAttachments;

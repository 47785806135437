import React from 'react';
import { Clear } from '@uicl/ui-core/dist/icons';
import IconButton from '@uicl/ui-core/dist/IconButton';

const XCircle: React.FunctionComponent<{ onClick: any; dataTestId: string }> = ({ onClick, dataTestId }) => (
  <div
    style={{
      position: 'absolute',
      top: 0,
      right: 0,
    }}
  >
    <IconButton dataTestId={dataTestId} onClick={onClick} icon={Clear} fillColor={'red'} hoverColor={'red'} />
  </div>
);

export default XCircle;

import React from 'react';
import Text from '@uicl/ui-core/dist/Text';
import { IAccountInfo, ILegalEntityInfo, IOnboardAccountRequestBody, IUsersInfo } from '../../api/onboarding/interface';
import Button from '@uicl/ui-core/dist/Button';
import { StyledBox, StyledDiv } from './style';
import RenderAccountInfo from './AccountInfo';
import { RenderUserInfo } from './UsersInfo';
import LoadingPage from '../LoadingPage';
import { FormState } from './formState';
import Success from './Success';
import ErrorInfo from './ErrorInfo';
import Directions from './Directions';
import { onboardAccount } from '../../api/onboarding';
import { useAppDispatch } from '../../reduxStore/reduxHooks';
import { IApiResult } from '../../api/interfaces';
import { IApiError } from '../../api/errorHandling/interfaces';

// TODO: Change it so at least 1 legal entity is required. We might need some basic validation here as well. Not sure backend will cover that.
// TODO: Change clear button to something more... CLEAR. Like Clear Form. Or Reset Form.
// TODO: Username needs to specify that it's an email?
const Onboarding: React.FC = () => {
  const [formState, setFormState] = React.useState(FormState.RequiresUserInput);
  const [accountInfo, setAccountInfo] = React.useState({} as IAccountInfo);
  const [users, setUsers] = React.useState([{}] as IUsersInfo[]);
  const [errorResponse, setErrorResponse] = React.useState<IApiError | undefined>(undefined);
  const dispatch = useAppDispatch();
  const updateAccountInfo = (newValues: Partial<IAccountInfo>) => {
    const updated = { ...accountInfo, ...newValues };
    setAccountInfo(updated);
  };

  const updateUser = (newValues: Partial<IUsersInfo>, userIndex: number) => {
    const userToUpdate = users[userIndex];
    const updatedUser = { ...userToUpdate, ...newValues };
    const stateCopy = users.slice();
    stateCopy[userIndex] = updatedUser;
    setUsers(stateCopy);
  };

  const addUser = () => {
    const stateCopy = users.slice();
    stateCopy.push({} as IUsersInfo);
    setUsers(stateCopy);
  };

  const deleteUserByIndex = (userIndex: number) => {
    if (users.length === 1) {
      setUsers([{}] as IUsersInfo[]);
      return;
    }
    const stateCopy = users.slice();
    stateCopy.splice(userIndex, 1);
    setUsers(stateCopy);
  };

  const mapFormToOnboardRequest = (accountInfo: IAccountInfo, users: IUsersInfo[]) => {
    return {
      accountRequest: {
        name: accountInfo.accountName,
        administratorEmail: 'bogus_email@optum.com',
        administratorName: 'Not Important',
        administratorPhoneNumber: '555-555-5555',
        phoneNumber: '555-555-5555',
        customerId: accountInfo.customerId,
        primaryAddress: {
          line1: '123 Elm St.',
          line2: 'Suite 500',
          city: 'Anytown',
          stateCode: 'CA',
          zipCode: '90210',
          country: 'United States',
        },
      },
      legalEntityRequests: null,
      userRequests: users,
    } as IOnboardAccountRequestBody;
  };

  const onSubmit = async () => {
    setFormState(FormState.Loading);

    const makeApiCall = async () => {
      try {
        await onboardAccount(mapFormToOnboardRequest(accountInfo, users), dispatch);
        setFormState(FormState.Successful);
      } catch (err) {
        const error = err as IApiResult;

        if (error?.status == 400) {
          setErrorResponse(error?.error);
        } else {
          setErrorResponse({
            errors: {
              NonUserError: 'Unable to create account at this time, please try again.',
              TraceId: error?.error?.traceId,
            },
          } as IApiError);
        }
        setFormState(FormState.RequiresUserInput);
      }
    };
    await makeApiCall();
  };

  const onClear = () => {
    setAccountInfo({} as IAccountInfo);
    setUsers([{}] as IUsersInfo[]);
  };

  const onCreateAnother = () => {
    onClear();
    setErrorResponse(undefined);
    setFormState(FormState.RequiresUserInput);
  };

  const renderHeader = () => (
    <StyledBox>
      <Text variant="large">Onboarding</Text>
    </StyledBox>
  );

  const renderForms = () => (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }} data-testid="onboardingErrors-testid">
        <Directions style={{ flex: 1 }} />
        {errorResponse === undefined ? (
          <></>
        ) : (
          <ErrorInfo style={{ flex: 1, color: 'red' }} errorResponse={errorResponse as IApiError} />
        )}
      </div>
      <hr />
      <div style={{ paddingBottom: '50px' }} data-testid="onboardingForm-testid">
        <RenderAccountInfo accountInfo={accountInfo} setAccountInfo={updateAccountInfo} />
        <RenderUserInfo users={users} updateUser={updateUser} deleteUserByIndex={deleteUserByIndex} addUser={addUser} />
        {renderFormButtons()}
      </div>
    </>
  );

  const renderFormButtons = () => (
    <div
      style={{
        display: 'flex',
        // justifyContent: 'space-between',
        flexWrap: 'wrap',
      }}
    >
      <div style={{ width: '10%', marginBottom: 10, marginTop: 10 }}>
        <Button
          dataTestId="submit-onboarding-button"
          domID="submit-button"
          name="Submit"
          size="medium"
          type="button"
          onClick={async () => await onSubmit()}
        />
      </div>
      <div style={{ width: '10% ', marginBottom: 10, marginTop: 10 }}>
        <Button
          dataTestId="clear-onboarding-button"
          domID="clear-button"
          name="Clear"
          size="medium"
          type="button"
          onClick={onClear}
        />
      </div>
    </div>
  );

  switch (formState) {
    case FormState.Loading:
      return <LoadingPage />;
    case FormState.Successful:
      return <Success createAnotherAccountHandler={onCreateAnother} />;
    default:
      return (
        <StyledDiv data-testid="onboardingPage-testid">
          <div style={{ display: 'flex' }}>
            <div style={{ width: '100px' }} />
            <div style={{ width: '100%' }}>
              {renderHeader()}
              <hr />
              {renderForms()}
            </div>
          </div>
        </StyledDiv>
      );
  }
};

export default Onboarding;

import React from 'react';
import { InsuredStyledDiv, ClaimGridDiv, ClaimGridCenterDiv, ClaimGridEndDiv } from './styles';
import Text from '@uicl/ui-core/dist/Text';
import { IClaimViewResponse } from '../../api/claimView/interface';

interface IProps {
  claimViewData: IClaimViewResponse;
}

const ClaimInformation: React.FC<IProps> = ({ claimViewData }) => {
  return (
    <div style={{ borderBottom: '2px solid #e0e0e0' }} data-testid="claimInformationContainer">
      <InsuredStyledDiv>
        <ClaimGridDiv data-testId={'ClaimInformation-claim-id'}>
          <Text color="secondary" variant="small">
            Claim ID
          </Text>
          <Text>{claimViewData.claimId}</Text>
        </ClaimGridDiv>
        <ClaimGridDiv>
          <ClaimGridCenterDiv data-testId={'ClaimInformation-payer-control-number'}>
            <div>
              <Text color="secondary" variant="small">
                Payer Control #
              </Text>

              <Text>{claimViewData.payerControlNumber}</Text>
            </div>
          </ClaimGridCenterDiv>
        </ClaimGridDiv>
        <ClaimGridDiv>
          <ClaimGridEndDiv />
        </ClaimGridDiv>
      </InsuredStyledDiv>
    </div>
  );
};

export default ClaimInformation;

import React from 'react';
import Text from '@uicl/ui-core/dist/Text';
import { TextProps } from '@uicl/ui-core/types/BrandCore/Text';

interface ITextLabel {
  text: any;
  label: string;
  labelVariant?: TextProps['variant'];
  textVariant?: TextProps['variant'];
}

export const TextWithLabel: React.FC<ITextLabel> = ({ text, label, labelVariant, textVariant }) => (
  <div>
    <Text color={'placeholder'} variant={labelVariant} component={'label'}>
      {label}
    </Text>
    <Text variant={textVariant}>{text}</Text>
  </div>
);

import React, { memo } from 'react';
import Grid from '@uicl/ui-core/dist/Grid';
import { IEligibilityPlanProvisionsResponse } from '../../../api/HistoricalTransactionDetailView/interfaces';
import { mockPlanProvisionsResponse } from '../../../api/HistoricalTransactionDetailView/mockResponse';
import { historicalPlanProvisionsTableColumns } from './HistoricalGridDetailsTableColumns';

interface IProps {
    historicalPlanProvisionsResult: IEligibilityPlanProvisionsResponse;
}

const HistoricalPlanProvisionsDetailData: React.FC<IProps> = () => {
  const handleMapping = () =>
    mockPlanProvisionsResponse.provisions.map((item) => {
      return {
        provisions: item.provision,
    };
    });

  return (
    <div style={{ marginTop: 20, paddingBottom: 50, paddingLeft:50, paddingRight:20 }}>
      <Grid
        dataTestId="eligibility-planprovisionsdetail-grid"
        domID="eligibility-planprovisions-grid"
        isConfigurable={false}
        records={handleMapping()}
        selectAllCheckboxDomID="select-all-test-id"
        selectionKey="text"
        supportSelection={false}
        columns={historicalPlanProvisionsTableColumns}
        maxHeight="500px"  
        isFixedHeader={true}
      />
    </div>
  );
};

export default memo(HistoricalPlanProvisionsDetailData);
import React from 'react';
import Group from '@uicl/ui-core/dist/Group';
import { DateFormat, FlexDiv } from '../../../../../utils/constants';
import Card from '@uicl/ui-core/dist/Card';
import { TextWithLabel } from '../../../../../utils/TextWithLabel';
import DateWrapper from '../../../../../utils/DateWrapper';
import { IAttachmentFileResponse } from '../../../../../api/payerAttachments/interface';
import Viewer from 'react-viewer';
import { ImageDecorator } from 'react-viewer/lib/ViewerProps';
import Button from '@uicl/ui-core/dist/Button';

interface IProps {
  attachmentImages: IAttachmentFileResponse[];
}

const AttachmentFileDetails: React.FC<IProps> = ({ attachmentImages }) => {
  const [isVisible, setIsVisible] = React.useState(false);

  const reactViewerImgSrcs = attachmentImages.map((attachment) => {
    const src: string = 'data:image/' + attachment?.fileMetadata?.fileExtension + ';base64,' + attachment.fileData;
    return { src } as ImageDecorator;
  });

  const Details = (
    <div id={'attachment-files-container'} style={{ display: 'flex', flexDirection: 'column', gap: '35px' }}>
      {attachmentImages?.length === 0 ? <div>No attachments available</div> : null}
      {attachmentImages?.map((attachment, index) => {
        if (!attachment || !attachment.fileMetadata) return;

        const metadata = attachment.fileMetadata;
        const imageDateWrapper = new DateWrapper(metadata.imageDate);
        const fileSize = Math.ceil(metadata.fileSize / 1024);

        return (
          <Card key={'attachment-file-card' + index}>
            <FlexDiv id={'attachment-details-' + index} style={{ gap: '35px' }}>
              <TextWithLabel label={'Type of Attachment'} text={metadata.typeOfAttachment} labelVariant={'xxSmall'} />
              <TextWithLabel label={'Orientation'} text={metadata?.orientation} labelVariant={'xxSmall'} />
              <TextWithLabel
                label={'Image Date'}
                text={imageDateWrapper.formatDateString(DateFormat.DisplayDate)}
                labelVariant={'xxSmall'}
              />
              <TextWithLabel label={'File Name'} text={metadata?.fileName} labelVariant={'xxSmall'} />
              <TextWithLabel label={'File Size'} text={fileSize + ' KB'} labelVariant={'xxSmall'} />
              <Button
                dataTestId={'view-attachment-image-button'}
                buttonType="emphasized"
                size="small"
                name={'View'}
                onClick={() => setIsVisible(true)}
              />
            </FlexDiv>
          </Card>
        );
      })}
      {isVisible ? (
        <Viewer visible={isVisible} onClose={() => setIsVisible(false)} images={reactViewerImgSrcs} />
      ) : (
        <></>
      )}
    </div>
  );
  return (
    <Group
      dataTestId={'attachment-file-details'}
      title={'Attachment Details'}
      initialIsCollapsed
      isAccordion
      description={'All details of the attachments associated to this claim'}
    >
      {Details}
    </Group>
  );
};

export default AttachmentFileDetails;

import { IHasPayerAttachmentSearchInputErrors } from '../api/payerAttachments/interface';

export const isFormIsValid = (payerAttachmentError: IHasPayerAttachmentSearchInputErrors) => {
  if (
    payerAttachmentError.hasErrorPAPayerID ||
    payerAttachmentError.hasErrorPAAttachmentId ||
    payerAttachmentError.hasErrorPABillingProviderTaxID ||
    payerAttachmentError.hasErrorPAInsuredID ||
    payerAttachmentError.hasErrorDateRangePicker
  ) {
    return false;
  } else {
    return true;
  }
};

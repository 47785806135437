import { IApiResponse } from './interfaces';
import { mapResponseDataToApiError, raiseErrorNotification } from './errorHandling';
import { IApiConfiguration } from './apiInstance';

export const handleApiResponse = (
  apiResult: any,
  dispatch?: any,
  apiConfiguration?: IApiConfiguration
): Promise<IApiResponse> => {
  const status = apiResult?.status ?? apiResult?.response?.status;

  /*istanbul ignore next*/
  if (status >= 200 && status <= 299) {
    return Promise.resolve(apiResult); //NOTE: Axios will return the apiResult.response when it resolves to the end consumer
  }

  const mappedError = mapResponseDataToApiError(apiResult);

  const rejection = { response: apiResult?.response, error: mappedError, status: status };

  /*istanbul ignore next*/
  if (status === 401) {
    window.location.href = '/login';
    return Promise.reject(rejection);
  }

  if (
    mappedError.title !== 'ERR_CANCELED' && // received abort Signal
    dispatch &&
    !apiConfiguration?.suppressNotification &&
    (!apiConfiguration?.notificationFilter || !apiConfiguration.notificationFilter(apiResult))
  ) {
    raiseErrorNotification(dispatch, mappedError);
  }

  return Promise.reject(rejection);
};

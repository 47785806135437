import React from "react";
import Text from '@uicl/ui-core/dist/Text';
import { InsuredStyledDiv, EraGridDiv, EraGridCenterDiv, EraGridEndDiv } from './styles';
import { IClaimPaymentInformation } from "api/EraSearchDetailView/interface";
import ClaimServiceComponent from './ClaimServiceComponent';
import { convertToDollar } from '../../../utils/constants';
import moment from 'moment';
import { DataItem } from '@uicl/ui-core/dist';


interface IProps {
    claimPayment: IClaimPaymentInformation;
    indexNumber: number;
}

const ClaimPaymentPanelComponent: React.FC<IProps> = ({ claimPayment, indexNumber }) => {
    return (
        <div style={{ borderBottom: '2px solid #e0e0e0' }} data-testid="claimsInfoContainer">
            <div style={{ marginBottom: 10, marginTop: 20 }}>
                <Text variant="largeBold">Claim Payment Information #{indexNumber}</Text>
            </div>
            <InsuredStyledDiv>
                <EraGridDiv>
                    <Text color="secondary" variant="small">
                        Patient
                    </Text>

                    <Text variant="smallBold">{claimPayment.patient}</Text>
                </EraGridDiv>
                <EraGridDiv>
                    <EraGridCenterDiv>
                        <Text color="secondary" variant="small">
                            Claim Type
                        </Text>

                        <Text variant="smallBold">{claimPayment.claimType}</Text>
                    </EraGridCenterDiv>
                </EraGridDiv>
                <EraGridDiv>
                    <EraGridEndDiv>
                        <Text color="secondary" variant="small">
                            Coverage Amount
                        </Text>

                        <Text variant="smallBold"> {
                            claimPayment.coverageAmount
                            ?convertToDollar(claimPayment.coverageAmount)
                            : null}
                        </Text>
                    </EraGridEndDiv>
                </EraGridDiv>
            </InsuredStyledDiv>

            <InsuredStyledDiv>
                <EraGridDiv>
                    <Text color="secondary" variant="small">
                        Member ID
                    </Text>

                    <Text variant="smallBold">{claimPayment.insuredId}</Text>
                </EraGridDiv>
                <EraGridDiv>
                    <EraGridCenterDiv>
                        <Text color="secondary" variant="small">
                            Billing Amount
                        </Text>

                        <Text variant="smallBold">{
                            claimPayment.billingAmount
                            ?convertToDollar(claimPayment.billingAmount)
                            : null}
                        </Text>
                    </EraGridCenterDiv>
                </EraGridDiv>
                <EraGridDiv>
                    <EraGridEndDiv>
                        <Text color="secondary" variant="small">
                            Claim Status
                        </Text>

                        <Text variant="smallBold">{(claimPayment.claimStatus)}</Text>
                    </EraGridEndDiv>
                </EraGridDiv>
            </InsuredStyledDiv>

            <InsuredStyledDiv>
                <EraGridDiv>
                    <Text color="secondary" variant="small">
                        Claim Control Number
                    </Text>

                    <Text variant="smallBold">{claimPayment.claimControlNumber}</Text>
                </EraGridDiv>
                <EraGridDiv>
                    <EraGridCenterDiv>
                        <Text color="secondary" variant="small">
                            Claim Payment Amount
                        </Text>

                        <Text variant="smallBold">{
                            claimPayment.claimPaymentAmount
                            ?convertToDollar(claimPayment.claimPaymentAmount)
                            : null}
                        </Text>
                    </EraGridCenterDiv>
                </EraGridDiv>
                <EraGridDiv>
                    <EraGridEndDiv>
                        <Text color="secondary" variant="small">
                            Payer Reference Number
                        </Text>

                        <Text variant="smallBold">{(claimPayment.payerReferenceNumber)}</Text>
                    </EraGridEndDiv>
                </EraGridDiv>
            </InsuredStyledDiv>

            <InsuredStyledDiv>
                <EraGridDiv>
                    <Text color="secondary" variant="small">
                        Render Provider
                    </Text>

                    <Text variant="smallBold">{claimPayment.renderingProvider}</Text>
                </EraGridDiv>
                <EraGridDiv>
                    <EraGridCenterDiv>
                        <Text color="secondary" variant="small">
                            Patient Responsibility Amount
                        </Text>

                        <Text variant="smallBold">{
                            claimPayment.patientResponsibilityAmount
                            ?convertToDollar(claimPayment.patientResponsibilityAmount)
                            : null}
                        </Text>
                    </EraGridCenterDiv>
                </EraGridDiv>
                <EraGridDiv>
                    <EraGridEndDiv>
                        <Text color="secondary" variant="small">
                            Claim Date
                        </Text>

                        <Text variant="smallBold">
                            {
                                claimPayment.claimDate
                                    ? moment(claimPayment.claimDate).format('M/DD/YYYY')
                                    : null
                            }
                        </Text>
                    </EraGridEndDiv>
                </EraGridDiv>
            </InsuredStyledDiv>
            {
                claimPayment.claimAdjustmentsCARC && claimPayment.claimAdjustmentsCARC.length > 0 ||
                    claimPayment.claimAdjustmentsRARC && claimPayment.claimAdjustmentsRARC.length > 0 ||
                    claimPayment.claimAdjustmentsPlb && claimPayment.claimAdjustmentsPlb.length > 0 ? (
                    <><Text variant="mediumBold">Claim Adjustments</Text>
                        <DataItem className="margin-top-bottom-10px" content={claimPayment.claimAdjustmentsCARC} size="small"></DataItem>
                        <DataItem className="margin-top-bottom-10px" content={claimPayment.claimAdjustmentsRARC} size="small"></DataItem>
                        <DataItem className="margin-top-bottom-10px" content={claimPayment.claimAdjustmentsPlb} size="small"></DataItem>
                    </>
                ) : null
            }

            <div style={{ borderTop: '2px solid #e0e0e0' }}>
                {claimPayment.services.map((claimService, index) => (
                    <ClaimServiceComponent claimService={claimService}></ClaimServiceComponent>

                ))}
            </div>

        </div>
    );
}

export default ClaimPaymentPanelComponent;
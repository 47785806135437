import React, { useEffect, useState } from 'react';
import integrations from '../../integrations';
import { Outlet, useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/optum-logo-ora-rgb.svg';
import Masthead from '@uicl/ui-core/dist/Masthead';
import Text from '@uicl/ui-core/dist/Text';
import { DisplayText, Navigation, title } from '../../utils/constants';
import { Modal, ModalBody } from '@uicl/ui-core/dist/Modal';
import ButtonGroup from '@uicl/ui-core/dist/ButtonGroup';
import Button from '@uicl/ui-core/dist/Button';
import { Chart, Home, Info, Warning, DiamondDetail } from '@uicl/ui-core/dist/icons';
import { useAppDispatch, useAppSelector } from '../../reduxStore/reduxHooks';
import checkIfActionAllowed from '../../reduxStore/userSummarySlice/checkIfActionAllowed';
import { UserActionTypes } from '../../reduxStore/userSlice/interfaces';
import {
  archiveAllNotifications,
  archiveNotification,
  markAllNotificationsAsRead,
  markNotificationAsRead,
  markNotificationAsShown,
} from '../../reduxStore/notificationSlice';
import { IconType, INotificationState } from '../../reduxStore/notificationSlice/interfaces';
import NotificationBodyComponent from '../Notifications/NotificationBodyComponent';
import { INotification } from '../Notifications/interfaces';

import { useAuth } from 'react-oidc-context';
import { SignoutRedirectArgs } from 'oidc-client-ts';

interface HeaderState {
  profileImage: any;
  imageData: any;
  brandImage: boolean;
  accountId: number | undefined;
  userName: string | undefined;
  showTool: boolean;
  isOpen: boolean;
  logoutmessege: string;
}

const signoutRedirectArgs: SignoutRedirectArgs = {
  extraQueryParams: {
    post_logout_redirect_uri: integrations.sentinel.post_logout_redirect_uri,
  },
};

const Header = () => {
  const [headerState, setHeaderState] = useState<HeaderState>({
    profileImage: '',
    imageData: '',
    brandImage: false,
    accountId: undefined,
    userName: '',
    showTool: false,
    isOpen: false,
    logoutmessege: 'Are you sure want to Logout?',
  });

  const navigate = useNavigate();
  const user = useAppSelector((state) => state.userSlice.user);
  const userSummary = useAppSelector((state) => state.userSummarySlice);
  const auth = useAuth();

  const loginRedirect = () => {
    navigate(Navigation.Login);
  };

  const logoutRedirect = async () => {
    await auth.signoutRedirect(signoutRedirectArgs);
  };

  const toggleLogoutModal = () => {
    setHeaderState({
      ...headerState,
      isOpen: !headerState.isOpen,
    });
  };

  const getNavMenuItems = () => {
    // NOTE: removing this first entry causes the menu to break when using Sections
    const navMenuItems = [[{ id: 1, label: 'Home', path: Navigation.Default, icon: Home }]];

    if (userSummary.accountId && userSummary.allowableActions.length) {
      //US7525027 : Add back as part of this story
      //navMenuItems.push({
      //  // @ts-ignore
      //  title: 'Claiming',
      //  sectionItems: [
      //    { id: 10, label: 'Claims', path: Navigation.Claims, icon: Chart },
      //    {
      //      id: 11,
      //      label: 'Claim Submission',
      //      path: Navigation.UnSubmittedClaims,
      //      icon: Chart,
      //    },
      //  ],
      //});

      //US7525027 : Remove as part of this story
      navMenuItems.push([
        {
          id: 10,
          label: 'Claims',
          path: Navigation.Claims,
          icon: Chart,
        },
      ]);

      navMenuItems.push([
        {
          id: 2,
          label: 'Payer Attachments',
          path: Navigation.PayerAttachments,
          icon: Chart,
        },
      ]);

      //TODO ERA search logic goes here for authorized or unauthorized user
      navMenuItems.push([{ id: 3, label: 'ERA / EFT Search', path: Navigation.EraSearch, icon: DiamondDetail }]);

      navMenuItems.push({
        // @ts-ignore
        title: 'Eligibility',
        sectionItems: [
          { id: 4, label: 'Historical Transactions', path: Navigation.HistoricalTransactions, icon: Chart },
        ],
      });

      return navMenuItems;
    }
  };

  const navMenuItems = getNavMenuItems();

  const onNavMenuItemSelectHandler = (event: any, navMenuItem: any) => {
    navigate(navMenuItem.activeItem.path);
  };

  const handleAdminNavigation = () => {
    const adminActions = {
      title: 'Admin',
      sectionItems: [
        {
          label: 'Account selection',
          id: 5,
          path: '/account-selection',
        },
      ],
    };
    const supportActions = {
      title: 'Support',
      sectionItems: [
        {
          label: 'Onboard Account',
          id: 6,
          path: Navigation.Onboarding,
        },
      ],
    };
    const actions = [];
    if (checkIfActionAllowed(UserActionTypes.MANAGEMULTIPLEACCOUNTS, userSummary)) {
      actions.push(adminActions);
    }
    if (checkIfActionAllowed(UserActionTypes.ONBOARDACCOUNT, userSummary)) {
      actions.push(supportActions);
    }
    return actions;
  };

  const dispatch = useAppDispatch();
  const notifications = useAppSelector((state) => state?.notificationSlice?.notifications);

  const [mappedNotifs, setMappedNotifs] = useState([] as INotification[] | undefined);
  const [popupNotifications, setPopupNotifications] = useState([] as INotificationState[] | undefined);

  const mapNotification = (notificationItem: INotificationState): INotification => {
    const icon = notificationItem.icon === IconType.Warning ? () => <Warning fillColor={'red'} /> : Info;
    return {
      ...notificationItem,
      icon: icon,
      description: <NotificationBodyComponent notification={notificationItem} />,
    };
  };

  useEffect(() => {
    const tempMappedNotifs = notifications.map(mapNotification);
    setMappedNotifs(tempMappedNotifs);
    setPopupNotifications(notifications.filter((n) => !n.isShown && !n.isRead).map(mapNotification));

    //cleanup on component unmount
    return () => {
      setMappedNotifs(undefined);
      setPopupNotifications(undefined);
    };
  }, [notifications]);

  const onMarkAllAsReadHandler = () => {
    dispatch(markAllNotificationsAsRead());
  };

  const onMarkAsReadHandler = (notificationId: string | number) => {
    dispatch(markNotificationAsRead({ notificationId }));
  };

  const onArchiveHandler = (notificationId: string | number) => {
    dispatch(archiveNotification({ notificationId }));
  };

  const onArchiveAllHandler = () => {
    dispatch(archiveAllNotifications());
  };

  const onPopupNotificationFinishHandler = (finishedNotification: any) => {
    if (!finishedNotification.isShown) {
      dispatch(markNotificationAsShown({ notificationId: finishedNotification.id }));
    }
  };

  //NOTE: Masthead ignores custom styling somehow. Styled components approach did not override component styles like expected. Height could be 55px instead of 48px
  return (
    <div>
      <div className="no-print">
        <Masthead
          domID="header-test-id"
          dataTestId="header-test-id"
          currentProductName={`${title.Dental} ${title.Connect}`}
          productNameOneLine={true}
          logoHeight="30"
          logoRedirect={Navigation.Default}
          onLogOutSelect={toggleLogoutModal}
          avatarImage={headerState.profileImage}
          integrations={integrations}
          isStandaloneLogin={false}
          onStandaloneLoginSelect={loginRedirect}
          supportMenuConfig={{
            shouldShow: false,
          }}
          onAvatarMenuSelect={(event, selection) => {
            navigate(selection.activeItem.path!.toString());
          }}
          customUserMenuItems={handleAdminNavigation()}
          // onProfileSelect={loginRedirect}
          userFirstName={user?.firstName}
          userLastName={user?.lastName}
          userEmail={user?.email}
          logoSrc={headerState.brandImage ? headerState.imageData : Logo}
          navMenuDisplay="sidebarOnly"
          navMenuItems={navMenuItems}
          onNavMenuSelect={onNavMenuItemSelectHandler}
          initialShowSidebar={true}
          showNotifications
          onPopupNotificationFinish={onPopupNotificationFinishHandler}
          popupNotifications={popupNotifications}
          popupNotificationTime={3000}
          popupNotificationAnimationTime={300}
          notifications={mappedNotifs}
          markAllAsRead={onMarkAllAsReadHandler}
          markAsRead={onMarkAsReadHandler}
          archive={onArchiveHandler}
          archiveAll={onArchiveAllHandler}
        />
        {/* this is the logout modal that pops up when you click Logout. might be something here with the ciam Logout (wasn't even wired up to start) */}
        {headerState.isOpen && (
          <Modal isOpen={headerState.isOpen}>
            <ModalBody>
              <div className="p-4" data-testid="logout-message-testid">
                <Text className="primaryBlueColor headerFont pb-3">{headerState.logoutmessege}</Text>
                <div className="pt-5">
                  <div className="d-flex justify-content-end">
                    <div data-testid="logout-container">
                      {/* @ts-ignore className 'unsupported'*/}
                      <ButtonGroup className="mx-2">
                        <Button
                          type="button"
                          className="submit-btn acceptButton"
                          name={DisplayText.Ok}
                          onClick={async () => await logoutRedirect()}
                          dataTestId="ok-button"
                        />
                        <Button
                          className="submit-btn acceptButton"
                          type="button"
                          onClick={toggleLogoutModal}
                          name={DisplayText.Cancel}
                          dataTestId="cancel-button"
                        />
                      </ButtonGroup>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
        )}
      </div>
      <Outlet />
    </div>
  );
};

export default Header;

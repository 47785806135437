import React, { memo } from 'react';
import Grid from '@uicl/ui-core/dist/Grid';
import { IEligibilityAgeLimitationsResponse } from '../../../api/HistoricalTransactionDetailView/interfaces';
import {mockAgeLimitationsResponse} from '../../../api/HistoricalTransactionDetailView/mockResponse';
import { historicalAgeLimitationsTableColumns } from './HistoricalGridDetailsTableColumns';

interface IProps {
    historicalAgeLimitationsResult: IEligibilityAgeLimitationsResponse;
}

const HistoricalAgeLimitationsDetailData: React.FC<IProps> = () => {
  const handleMapping = () =>
    mockAgeLimitationsResponse.ageLimitations.map((item) => {
      return {
        procedureCode: item.procedureCode,
        description: item.description,
        restriction: item.message,
    };
    });

  return (
    <div style={{ marginTop: 20, paddingBottom: 50, paddingLeft:50, paddingRight:20 }}>
      <Grid
        dataTestId="eligibility-agelimitationsdetail-grid"
        domID="eligibility-agelimitations-grid"
        isConfigurable={false}
        records={handleMapping()}
        selectAllCheckboxDomID="select-all-test-id"
        selectionKey="text"
        supportSelection={false}
        columns={historicalAgeLimitationsTableColumns}
        maxHeight="500px"  
        isFixedHeader={true}
      />
    </div>
  );
};
export default memo(HistoricalAgeLimitationsDetailData);

import React, { Dispatch, SetStateAction } from 'react';
import Text from '@uicl/ui-core/dist/Text';
import DateRangePicker from '@uicl/ui-core/dist/DateRangePicker';
import SelectDropdown from '@uicl/ui-core/dist/SelectDropdown';
import { Button, ButtonGroup } from '@uicl/ui-core/dist';
import { DateFilter,IHistoricalTransactionsSearchRequestFrontBody } from '../../api/HistoricalTransactions/interfaces';
import moment, { Moment } from 'moment';
import HistoricalSearchFilterModal from './HistoricalSearchFilterModal';

interface InitialOptions {
  label: string;
  value: string;
  dataTestId: string;
  id: string;
}

interface IProps {
  historicalTransactionsSearchRequestBody: IHistoricalTransactionsSearchRequestFrontBody;
  handleOnReset: () => void;
  handleOnSearch: () => void;
  handleOnChange: (e?: React.ChangeEvent<HTMLInputElement>, dateFilterOption?: DateFilter) => void;
  setStartDate: Dispatch<SetStateAction<Moment>>;
  setCurrentTransactions: Dispatch<SetStateAction<string>>;
  setEndDate: Dispatch<SetStateAction<Moment>>;
  endDate: Moment;
  startDate: Moment;
  handleOnMainReset: () => void;
  initialOption: InitialOptions | null;
  handleOnClose: () => void;
  handleOnSearchFilterClick: () => void;
  isOpen: boolean;
  initialErrorOption: InitialOptions | null;
  currentTransactionsOption: InitialOptions[];
  currentErrorOption: InitialOptions[];
  setCurrentErrorOption: Dispatch<SetStateAction<string>>;
  setInitiOption: Dispatch<SetStateAction<{ label: string; value: string; dataTestId: string; id: string } | null>>;
}

const HistoricalSearchComponent: React.FC<IProps> = ({
  historicalTransactionsSearchRequestBody,
  handleOnSearch,
  handleOnReset,
  handleOnChange,
  setStartDate,
  setEndDate,
  endDate,
  startDate,
  handleOnMainReset,
  initialOption,
  setCurrentTransactions,
  handleOnClose,
  handleOnSearchFilterClick,
  initialErrorOption,
  isOpen,
  currentTransactionsOption,
  currentErrorOption,
  setCurrentErrorOption,
  setInitiOption,
}) => {
  const minDate = moment().subtract(15, 'months');
  return (
    <div data-testid="historical-search-component-testid">
      <Text> Historical Transactions</Text>
      <div style={{ marginLeft: 15, display: 'flex', marginTop: 10 }}>
        <div style={{ marginLeft: 15, display: 'flex' }}>   
          <DateRangePicker
            dataTestId="test-dateRangePicker_date"
            domID="test_daterange"
            errorMessage="error"
            label="Transaction Date"
            openDirection="down"
            showCalendarIcon
            size="medium"
            initialEndDate={endDate}
            initialStartDate={startDate}
            maxDate={moment()}
            minDate={minDate}
            // onStartInputChange={(date) => {
            //   //TODO https://jira.healthcareit.net/browse/DCP2-6995
            //   /* istanbul ignore next */
            //   setSelectedDate(date as any);
            // }}

            onDatesChange={(date) => {
              //TODO https://jira.healthcareit.net/browse/DCP2-6995
              /* istanbul ignore next */
              if (date.startDate === null) return;
              /* istanbul ignore next */
              const start = moment(date.startDate);

              /* istanbul ignore next */
              setStartDate(start);

              //TODO https://jira.healthcareit.net/browse/DCP2-6995
              /* istanbul ignore next */
              if (date.endDate !== null) {
                const end = moment(date.endDate);
                setEndDate(end);
                const currentdate = moment(new Date()).format('L');
                const startD = start.format('L');
                const endD = end.format('L');

                if ((endD != currentdate) || (startD != currentdate) ){
                  setInitiOption({
                    dataTestId: 'nofilter-testid',
                    id: 'filter',
                    label: 'No filter',
                    value: '0',
                  });
                  setCurrentTransactions('0');
                }
               
              }
            }}
          />
          <div style={{ marginLeft: 15, display: 'flex' }}>
            <SelectDropdown
              ariaLabel="ARIA label"
              dataTestId="test-selectcurrent_trans"
              domID="test-id_trans"
              size="small"
              label="Current Transactions"
              initialValue={initialOption!}
              onChange={(opt) => {
                const value = opt ? opt.value : null;
                setCurrentTransactions(value as string);
                const currentdate = moment(new Date()).format('L');
                const startD = startDate.format('L');
                const endD = endDate.format('L');

                if ((startD != currentdate) ||(endD != currentdate) ){
                  setInitiOption({
                    dataTestId: 'nofilter-testid',
                    id: 'filter',
                    label: 'No filter',
                    value: '0',
                  });
                  setCurrentTransactions('0');
                }          
              }}
              menuWidth={400}
              options={currentTransactionsOption}
            />
          </div>
          <div style={{ marginLeft: 5, marginTop: 19, display: 'flex' }}>
            <Button
              dataTestId="historical-search_filter-button"
              domID="id_search_filter"
              name="Search Filters"
              buttonType="diminished"
              size="small"
              onClick={handleOnSearchFilterClick}
            />
            <HistoricalSearchFilterModal
              historicalTransactionsSearchRequestBody={historicalTransactionsSearchRequestBody}
              handleOnReset={handleOnReset}
              handleOnChange={handleOnChange}
              isOpen={isOpen}
              onClose={handleOnClose}
              onSave={handleOnClose}
              initialErrorOption={initialErrorOption}
              currentErrorOption={currentErrorOption}
              setCurrentErrorOption={setCurrentErrorOption}
            />
          </div>
          <div style={{ marginLeft: 10, display: 'flex', marginTop: 14 }}>
            <ButtonGroup>
              <Button
                dataTestId="button_search"
                domID="id_search"
                name="Search"
                buttonType="standard"
                size="small"
                onClick={handleOnSearch}
              />
              <Button
                dataTestId="button_refresh"
                domID="id_refresh"
                name="Refresh"
                buttonType="standard"
                size="small"
                onClick={handleOnSearch}
              />
              <Button
                dataTestId="button_main_reset"
                domID="id_main_reset"
                name="Reset"
                buttonType="standard"
                size="small"
                type="reset"
                onClick={handleOnMainReset}
              />
            </ButtonGroup>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoricalSearchComponent;

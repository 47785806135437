export const selectedAccountDetailColumn = new Set([
  {
    dataName: 'administratorName',
    text: 'Account Contact Name',
    sortable: false,
    isSorted: 0,
  },
  {
    dataName: 'name',
    text: 'Name',
    sortable: false,
    isSorted: 0,
  },
]);

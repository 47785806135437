export const setSessionStorage = (key: string, value: any) => {
  if (typeof value == 'string') {
    sessionStorage.setItem(key, value);
  } else {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
};

export const getFromSessionStorage = (key: string) => {
  return sessionStorage.getItem(key);
};

import { apiInstance } from '../apiInstance';
import { ILegalEntityResponse } from './interface';

export const getLegalEntities = (
  accountId: string,
  offset: number,
  limit: number,
  dispatch?: any
): Promise<ILegalEntityResponse> => {
  return new Promise((resolve, reject) => {
    apiInstance(dispatch)
      .get(`/account/${accountId}/legalentity`, { params: { offset, limit } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

import userSlice from './userSlice';
import authSlice from './authSlice';
import legalentitySlice from './legalentitySlice';
import claimDetailSlice from './claimDetailSlice';
import userSummarySlice from './userSummarySlice';
import attachmentSlice from './attachmentSlice';
import notificationSlice from './notificationSlice';
import attachmentImagesSlice from './attachmentImagesSlice';

export default {
  userSlice,
  authSlice,
  legalentitySlice,
  claimDetailSlice,
  userSummarySlice,
  attachmentSlice,
  notificationSlice,
  attachmentImagesSlice,
};

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { Provider } from 'react-redux';
import UICLThemeProvider from '@uicl/ui-core/dist/UICLThemeProvider';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { setupStore } from './reduxStore/rootStore';
import router from './Routes';

const App = () => {
  return (
    <Provider store={setupStore()}>
      {/* @ts-ignore: UICL things */}
      <UICLThemeProvider theme="base" isAnalyticsEnabled={false}>
        <RouterProvider router={createBrowserRouter(router)} />
      </UICLThemeProvider>
    </Provider>
  );
};

export default App;

import React from 'react';
import PayerAttachments from '../../components/PayerAttachments/AttachmentSearch/index';

const SearchPayerAttachments = () => {
  return (
    <div data-testid="payerattachment-search-page-testid" style={{ marginTop: 70, height: '100vh' }}>
      <PayerAttachments />
    </div>
  );
};

export default SearchPayerAttachments;

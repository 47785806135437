import { IHistoricalTransactionFileDataResponse } from "./interfaces";
import { mockEligibilityDetailResponse } from "./mockResponse"
import { apiInstance } from '../apiInstance';

export const getHistoricalTransactionFileData = (transactionId:string, traceType:string, dispatch?: any)
: Promise<IHistoricalTransactionFileDataResponse> => {
    return new Promise((resolve, reject) => {
      apiInstance(dispatch)
        .get(`/eligibility/${transactionId}/x12?transactionType=${traceType}`, )        
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  export const getEligibilityOverview = (transactionId:string) => {
    return mockEligibilityDetailResponse
}
export const historicalCoverageTableColumns = new Set([
    {
        dataName: 'procedureCode',
        text: 'Procedure Code or ServiceType',
        sortable: true,
        isSorted: 0,
        align: 'left',
    },

    {
        dataName: 'inNetwork',
        text: 'In Network',
        sortable: true,
        isSorted: 0,
        align: 'left',
    },

    {
        dataName: 'outNetwork',
        text: 'Out of Network',
        sortable: true,
        isSorted: 0,
        align: 'left',
    },
    
    {
        dataName: 'deductibleApplies',
        text: 'Deductible Applies',
        sortable: true,
        isSorted: 0,
        align: 'left',
    },
     
    {
        dataName: 'message',
        text: 'Message',
        sortable: true,
        isSorted: 0,
        align: 'left',
    }
]);

export const historicalFrequencyLimitationsTableColumns = new Set([
    {
        dataName: 'procedureCode',
        text: 'Procedure Code or ServiceType',
        sortable: true,
        isSorted: 0,
        align: 'left',
    },

    {
        dataName: 'plan',
        text: 'Plan',
        sortable: true,
        isSorted: 0,
        align: 'left',
    },

    {
        dataName: 'restriction',
        text: 'Restriction',
        sortable: true,
        isSorted: 0,
        align: 'left',
    },
    
    {
        dataName: 'lastVisit',
        text: 'Last Visit',
        sortable: true,
        isSorted: 0,
        align: 'left',
    },
]);

export const historicalAgeLimitationsTableColumns = new Set([
    {
        dataName: 'procedureCode',
        text: 'Procedure Code or ServiceType',
        sortable: true,
        isSorted: 0,
        align: 'left',
    },

    {
        dataName: 'description',
        text: 'Plan',
        sortable: true,
        isSorted: 0,
        align: 'left',
    },

    {
        dataName: 'message',
        text: 'Message',
        sortable: true,
        isSorted: 0,
        align: 'left',
    },
]);

export const historicalDeductiblesTableColumns = new Set([
    {
        dataName: 'deductible',
        text: 'Deductible',
        sortable: true,
        isSorted: 0,
        align: 'left',
    },

    {
        dataName: 'amountInfo',
        text: '',
        sortable: true,
        isSorted: 0,
        align: 'left',
    },

    {
        dataName: 'amount',
        text: 'Amount',
        sortable: true,
        isSorted: 0,
        align: 'left',
    },
]);

export const historicalMaximumsTableColumns = new Set([
    {
        dataName: 'maximum',
        text: 'Maximum',
        sortable: true,
        isSorted: 0,
        align: 'left',
    },

    {
        dataName: 'amountInfo',
        text: '',
        sortable: true,
        isSorted: 0,
        align: 'left',
    },

    {
        dataName: 'inNetwork',
        text: 'In Network',
        sortable: true,
        isSorted: 0,
        align: 'left',
    },
    
    {
        dataName: 'outOfNetwork',
        text: 'Out of Network',
        sortable: true,
        isSorted: 0,
        align: 'left',
    },
]);

export const historicalPlanProvisionsTableColumns = new Set([
    {
        dataName: 'provisions', 
        text: '',
        sortable: true,
        isSorted: 0,
        align: 'left',
    },
]);
import React, { useEffect, useState } from 'react';
import { setAppError } from '../../reduxStore/authSlice';
import { useAppDispatch } from '../../reduxStore/reduxHooks';
import { IAdminAccountDetail } from '../../api/user/interface';
import { useLocation } from 'react-router-dom';
import { handleSelectedAccount } from '../../utils/constants';
import SelectedAccountDetails from '../../components/AccountSelection/SelectedAccountDetails';
import { setSessionStorage } from '../../utils/sessionStorage';
import { IPagingOptions } from '../../allOtherInterfaces/pagingOptionInterface';
import styled from 'styled-components';
import Text from '@uicl/ui-core/dist/Text';
import AccountSelectionTableData from '../../components/AccountSelection/AccountSelectionTableData';
import Button from '@uicl/ui-core/dist/Button';
import Previous from '@uicl/ui-core/dist/Previous';
import Next from '@uicl/ui-core/dist/Next';
import { getAccountInfo } from '../../api/account';

interface InititalOptions {
  value: string;
  label: string;
}

export interface ISelectedAccount {
  adminAccountId: string;
  name: string;
  administratorName: string;
}

const StyledBox = styled.div`
  display: flex;
  justify-content: space-between;
`;
const limit = 30;

const AccountSelection = () => {
  const dispatch = useAppDispatch();
  const [accountList, setAccountList] = useState<IAdminAccountDetail[]>([]);
  const [selectedAdminAccountDetail, setSelectedAdminAccountDetail] = useState<ISelectedAccount>();
  const location = useLocation();
  const [totalCount, setTotalCount] = useState(0);
  const [pagingOptions, setPagingOptions] = useState<IPagingOptions>({} as IPagingOptions);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const handleGetAccountList = async () => {
      try {
        const offset = (page - 1) * limit;
        const response = await getAccountInfo(offset, limit, dispatch);
        const newMap = response.results.map((result) => {
          return {
            adminAccountId: result.id,
            ...result,
          };
        });

        setAccountList(newMap);
        setTotalCount(response.resultCount);
        setPagingOptions(response.pagingOptions);
      } catch (err) {
        //TODO: https://jira.healthcareit.net/browse/DCP2-7345 proper handling of error in catch block
        const error = err as Error;
        dispatch(setAppError({ message: error.message, isOpen: true }));
      }
    };
    handleGetAccountList();
  }, [page]);

  useEffect(() => {
    //if account exist in storage, set selected account
    const selectedAccount = handleSelectedAccount();
    if (selectedAccount) {
      setSelectedAdminAccountDetail(selectedAccount);
    }
  }, [accountList, location]);

  const handleUseAccount = (adminAccountId: string) => {
    const findIndex = accountList.findIndex((result) => result.adminAccountId === adminAccountId);

    const selectedAccount = accountList[findIndex];

    setSessionStorage(
      'selectedAccount',
      JSON.stringify({
        adminAccountId: selectedAccount.adminAccountId,
        name: selectedAccount.name,
        administratorName: selectedAccount.administratorName,
      })
    );

    window.location.href = '/';
  };

  const handlePageNext = () => {
    setPage((prev) => prev + 1);
  };

  const handlePagePrevious = () => {
    /* istanbul ignore next */
    setPage((prev) => prev - 1);
  };

  const renderHeader = () => (
    <StyledBox>
      <div style={{ marginTop: 40 }}>
        <Text variant="large">Account Selection</Text>
      </div>
    </StyledBox>
  );

  return (
    <div style={{ padding: 10, height: '100vh' }}>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '100px' }} />
        <div style={{ width: '100%' }}>
          {selectedAdminAccountDetail && (
            <div style={{ marginTop: 40, marginBottom: 40 }}>
              <Text variant="large">Selected account</Text>
              <SelectedAccountDetails selectedAdminAccountDetail={selectedAdminAccountDetail} />
            </div>
          )}
          {renderHeader()}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Text variant="small" className="pagination-text-color">
              {pagingOptions.offset} of {totalCount}
            </Text>
            <div style={{ display: 'flex' }}>
              <Button
                buttonType="deEmphasized"
                dataTestId={'left-arrow'}
                domID={'left-arrow'}
                icon={Previous}
                size="small"
                type="button"
                className="action-buttons"
                onClick={handlePagePrevious}
                disabled={page === 1 ? true : false}
              />
              <Button
                buttonType="deEmphasized"
                dataTestId={'right-arrow'}
                domID={'right-arrow'}
                icon={Next}
                size="small"
                type="button"
                className="action-buttons"
                disabled={pagingOptions.morePages ? false : true}
                onClick={handlePageNext}
              />
            </div>
          </div>
          <AccountSelectionTableData accountList={accountList} handleUseAccount={handleUseAccount} />
        </div>
      </div>
    </div>
  );
};

export default AccountSelection;

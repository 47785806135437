import React from 'react';
import Colors from '@uicl/ui-core/dist/BasePalette';
import { Clock, Calendar } from '@uicl/ui-core/dist/icons';
import Text from '@uicl/ui-core/dist/Text';
import { IAttachmentFileInfo } from '../../../api/attachments/interface';
import DateWrapper from '../../../utils/DateWrapper';
import { BigChart } from './styles';
import { AttachmentTypes, OrientationTypes } from '../../../api/attachments/enums';
import { DateFormat } from '../../../utils/constants';

const attachmentIdTypeInputArray = (attachmentOverview: IAttachmentFileInfo) => {
  return [
    {
      label: 'ApplicationType',
      domID: 'overview-application-type',
      disabled: true,
      initialValue: AttachmentTypes[attachmentOverview.documentTypeCode!],
      width: '25%',
      marginBottom: 10,
      key: 'applicationType',
    },
    {
      label: 'Comment',
      domID: 'overview-comment',
      disabled: true,
      initialValue: attachmentOverview?.narrative,
      width: '15%',
      marginBottom: 10,
      marginLeft: 5,
      key: 'comment',
    },
  ];
};

interface AttachmentOverviewProps {
  getAttachmentsData: () => IAttachmentFileInfo[];
}

const AttachmentOverview: React.FC<AttachmentOverviewProps> = ({ getAttachmentsData }: AttachmentOverviewProps) => {
  const attachmentOverviewInfos = getAttachmentsData();

  return attachmentOverviewInfos ? (
    <div>
      {attachmentOverviewInfos?.length === 0 ? (
        <div style={{ textAlign: 'center' }}>
          <Text color="secondary" variant="small">
            No Attachments Added
          </Text>
        </div>
      ) : (
        <></>
      )}
      {attachmentOverviewInfos.map((attachmentOverviewInfo: IAttachmentFileInfo, rowIndex: number) => (
        <div
          key={'attachmentOverview-' + rowIndex}
          style={{
            marginTop: 20,
            border: `1px solid ${Colors.neutral10}`,
            borderRadius: 5,
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <div style={{ marginTop: 20, width: '10%' }}>
            <BigChart />
          </div>
          <div style={{ marginTop: 20, width: '80%' }}>
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                <div style={{ marginBottom: 20, width: '25%', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                  <Text>{attachmentOverviewInfo.fileName?.toString()}</Text>

                  <Text color="secondary" variant="small">
                    {attachmentOverviewInfo.fileSize + ' KB'}
                  </Text>
                </div>
                <div style={{ marginBottom: 20, width: '25%', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                  <Text color="secondary" variant="small">
                    Orientation Type
                  </Text>

                  <Text>{OrientationTypes[attachmentOverviewInfo.orientationType]}</Text>
                </div>
                <div style={{ marginBottom: 20, width: '25%', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                  <Text color="secondary" variant="small">
                    <Calendar size={'small'} />
                    {new DateWrapper(attachmentOverviewInfo.imageDate).formatDateString(DateFormat.DisplayDate)}
                  </Text>

                  <Text></Text>
                </div>
                <div style={{ marginBottom: 20, width: '25%', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                  <Text color="secondary" variant="small">
                    <Clock size={'small'} />
                    {new DateWrapper(attachmentOverviewInfo.imageDate).formatDateString(DateFormat.HoursMinFormat)}
                  </Text>

                  <Text></Text>
                </div>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {attachmentIdTypeInputArray(attachmentOverviewInfo).map((item, index) => (
                  <div
                    key={'attachmentidtype-' + index}
                    style={{ width: item.width, justifyContent: 'space-between', flexWrap: 'wrap' }}
                  >
                    <Text color="secondary" variant="small">
                      {item.label}
                    </Text>
                    {/*TODO wrap this in a tooltip showing full comment content; could not figure it out from the UICL docs :( */}

                    <Text singleLine>{item.initialValue}</Text>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <></>
  );
};

export default AttachmentOverview;

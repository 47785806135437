export const accountDetailColumn = new Set([
  {
    dataName: 'administratorName',
    text: 'Account Contact Name',
    sortable: false,
    isSorted: 0,
  },
  {
    dataName: 'name',
    text: 'Name',
    sortable: false,
    isSorted: 0,
  },
  {
    dataName: 'administratorPhoneNumber',
    text: 'Account Contact Number',
    sortable: false,
    isSorted: 0,
  },
  {
    dataName: 'administratorEmail',
    text: 'Account Contact Email',
    sortable: false,
    isSorted: 0,
  },
  {
    dataName: 'actions',
    text: 'Actions',
    sortable: false,
    isSorted: 0,
    cellType: 'button',
  },
]);

import React from 'react';
import { CollapsePanel, CollapsePanelDetails, CollapsePanelTitle } from '@uicl/ui-core/dist/CollapsePanel';
import { IEligibilityDeductiblesResponse, IEligibilityMaximumsResponse, IEligibilityPlanProvisionsResponse, IEligibilityCoverageResponse, IEligibilityFrequencyLimitationsResponse, IEligibilityAgeLimitationsResponse} from '../../../api/HistoricalTransactionDetailView/interfaces';
import HistoricalDeductiblesAndMaximumsDetailData from './DeductiblesGridComponent';
import HistoricalPlanProvisionsDetailData from './PlanProvisionsGridComponent';
import HistoricalMaximumsDetailData from './MaximumsGridComponent';
import HistoricalCoverageDetailData from './CoverageDetailGridComponent';
import HistoricalAgeLimitationsDetailData from './AgeLimitationsGridComponent';
import HistoricalFrequencyLimitationsDetailData from './FrequencyLimitationsDetailsGridComponent';

interface IProps {
    mockDeductiblesResponse: IEligibilityDeductiblesResponse;
    mockMaximumsResponse: IEligibilityMaximumsResponse;
    mockPlanProvisionsResponse: IEligibilityPlanProvisionsResponse;
    mockCoverageResponse: IEligibilityCoverageResponse;
    mockFrequencyLimitationsResponse : IEligibilityFrequencyLimitationsResponse;
    mockAgeLimitationsResponse : IEligibilityAgeLimitationsResponse 
  }
    
  const HistoricalTransactionsGridsComponent: React.FC<IProps> = ({mockDeductiblesResponse, mockMaximumsResponse, mockPlanProvisionsResponse, mockCoverageResponse, mockFrequencyLimitationsResponse, mockAgeLimitationsResponse}) => {
    return (
    <div data-testid="historicalTransactionsGridsContainer">
        <CollapsePanel dataTestId="test-collapsePanel_deductiblesandmaximumsdetail">
            <CollapsePanelTitle aria-controls="collapse-details" domID="collapse-title">
                Deductibles & Maximums
            </CollapsePanelTitle>
            <CollapsePanelDetails>
                <HistoricalDeductiblesAndMaximumsDetailData historicalDeductiblesResult={mockDeductiblesResponse} />
                <HistoricalMaximumsDetailData historicalMaximumsResult={mockMaximumsResponse} />
            </CollapsePanelDetails>
        </CollapsePanel>
        <CollapsePanel dataTestId="test-collapsePanel_planprovisionsdetail">
            <CollapsePanelTitle aria-controls="collapse-details" domID="collapse-title">
                Plan Provisions
            </CollapsePanelTitle>
            <CollapsePanelDetails>
                <HistoricalPlanProvisionsDetailData historicalPlanProvisionsResult={mockPlanProvisionsResponse} />
            </CollapsePanelDetails>
        </CollapsePanel>
        <CollapsePanel dataTestId="test-collapsePanel_coveragedetail">
            <CollapsePanelTitle aria-controls="collapse-details" domID="collapse-title">
                Coverage
            </CollapsePanelTitle>
            <CollapsePanelDetails>
                <HistoricalCoverageDetailData eligibilityCoverageResult={mockCoverageResponse} />
            </CollapsePanelDetails>
        </CollapsePanel>
        <CollapsePanel dataTestId="test-collapsePanel_frequencylimitations">
            <CollapsePanelTitle aria-controls="collapse-details" domID="collapse-title">
                Frequency Limitations
            </CollapsePanelTitle>
            <CollapsePanelDetails>
                <HistoricalFrequencyLimitationsDetailData historicalFrequencyLimitationsResult={mockFrequencyLimitationsResponse} />
            </CollapsePanelDetails>
        </CollapsePanel>
        <CollapsePanel dataTestId="test-collapsePanel_agelimitations">
            <CollapsePanelTitle aria-controls="collapse-details" domID="collapse-title">
                Age Limitations
            </CollapsePanelTitle>
            <CollapsePanelDetails>
                <HistoricalAgeLimitationsDetailData historicalAgeLimitationsResult={mockAgeLimitationsResponse} />
            </CollapsePanelDetails>
        </CollapsePanel>
    </div>
  );
};
export default HistoricalTransactionsGridsComponent;
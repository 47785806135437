import React from 'react';
import styled from 'styled-components';
import UIPalette from '@uicl/ui-core/dist/UIPalette';
import Text from '@uicl/ui-core/dist/Text';

const Footer = () => {
  return (
    <div style={{ position: 'fixed', bottom: 0, width: '100%' }} className="no-print">
      <div style={{ display: 'flex' }}>
        <div style={{ width: '70px' }} />
        <div style={{ width: '100%' }}>
          <FooterContainer data-testid="footer-container">
            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
              <StyledLinkText variant="smallLink">Privacy Policy</StyledLinkText>
            </a>
            <a href="/terms-of-use" target="_blank" rel="noopener noreferrer">
              <StyledLinkText variant="smallLink">Terms Of Use</StyledLinkText>
            </a>
            <StyledCopyrightText variant="small">© {new Date().getFullYear()} Optum</StyledCopyrightText>
          </FooterContainer>
        </div>
      </div>
    </div>
  );
};

const StyledLinkText = styled(Text)`
  color: white;
  text-align: left;
  padding-left: 1rem;
`;

const StyledCopyrightText = styled(StyledLinkText)`
  margin-left: auto;
  padding-right: 1rem;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${UIPalette.Colors.Content.Primary};
  align-items: center;
  height: 2rem;
  padding-left: 1rem;
`;

export default Footer;

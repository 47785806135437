import React, { memo } from 'react';
import Grid from '@uicl/ui-core/dist/Grid';
import { IEligibilityCoverageResponse } from '../../../api/HistoricalTransactionDetailView/interfaces';
import {mockCoverageResponse} from '../../../api/HistoricalTransactionDetailView/mockResponse';
import { historicalCoverageTableColumns } from './HistoricalGridDetailsTableColumns';

interface IProps {
    eligibilityCoverageResult: IEligibilityCoverageResponse;
}

const HistoricalCoverageDetailData: React.FC<IProps> = () => {
  const handleMapping = () =>
    mockCoverageResponse.coverage.map((item) => {
      return {
        procedureCode: item.procedureCode,
        inNetwork: item.inNetwork,
        outNetwork: item.outNetwork,
        deductibleApplies: item.deductibleApplies,
        message: item.messages,
    };
    });

  return (
    <div style={{ marginTop: 20, paddingBottom: 50, paddingLeft:50, paddingRight:20 }}>
      <Grid
        dataTestId="eligibility-coveragedetail-grid"
        domID="eligibility-coverage-grid" 
        isConfigurable={false}
        records={handleMapping()}
        selectAllCheckboxDomID="select-all-test-id"
        selectionKey="text"
        supportSelection={false}
        columns={historicalCoverageTableColumns}
        maxHeight="500px"
        isFixedHeader={true}
      />
    </div>
  );
};

export default memo(HistoricalCoverageDetailData);
import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../reduxStore/reduxHooks';
import { isAuthenticated } from '../utils/isAuthenticated';
import { handleSelectedAccount, Navigation } from '../utils/constants';
import checkIfActionAllowed from '../reduxStore/userSummarySlice/checkIfActionAllowed';
import { UserActionTypes } from '../reduxStore/userSlice/interfaces';
import { useAuth } from 'react-oidc-context';

interface TProps {
  children: React.ReactElement;
}

const PrivateRoute: React.FC<TProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const userSummary = useAppSelector((state) => state.userSummarySlice);
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  const { activeNavigator, error, isLoading, user, isAuthenticated } = useAuth();

  useEffect(() => {
    if (user?.access_token) {
      const selectedAccount = handleSelectedAccount();
      if (checkIfActionAllowed(UserActionTypes.MANAGEMULTIPLEACCOUNTS, userSummary)) {
        if (!selectedAccount && location.pathname !== Navigation.AccountSelection) {
          navigate(Navigation.AccountSelection);
        }
      }
      setSearchParams({});
    }
    // eslint-disable-next-line
  }, [user?.access_token, isAuthenticated]);

  if (isLoading) {
    return <div>Loading...</div>;
  } else {
    if (isAuthenticated) {
      return children;
    } else {
      return <Navigate to="/login" />;
    }
  }
};

export default PrivateRoute;

import React, { useEffect } from 'react';
import Text from '@uicl/ui-core/dist/Text';

const NotFound = () => {
  useEffect(() => {
    document.title = 'Not Found | Change Healthcare';
  }, []);

  return (
    <div data-testid="notfound">
      <div style={{ display: 'flex' }}>
        <div style={{ width: '100px' }} />
        <div style={{ width: '100%' }}>
          <div className="loginVh d-flex justify-content-center align-items-center">
            <div className="loginBox">
              <div className="p-12">
                <div className="text-center">
                  <Text variant="large">Page Not Found</Text>
                  <Text variant="small" className="pt-3">
                    The page you were looking for could not be found{' '}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;

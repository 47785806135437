import React from 'react';
import PrivateRoute from './PrivateRoute';
import ErrorBoundary from './ErrorBoundary';
import Welcome from '../Routes/Welcome';
import Login from './Login';
import PageRedirect from './PageRedirect';
import NotFound from './NotFound';
import Unauthorized from './Unauthorized';
import PrivacyPolicy from '../components/Footer/PrivacyPolicy';
import TermsOfUse from '../components/Footer/TermsOfUse';
import Claims from './Claims';
import Health from './Health';
import Logout from './Login/Logout';
import Header from '../components/Header';
import AccountSelection from './AccountSelection';
import Onboarding from './Onboarding';
import { Navigation } from '../utils/constants';
import Footer from '../components/Footer';
import AuthContainer from '../components/AuthContainer';
import Error from '../Routes/Error';
import EraSearch from './EraSearch';
import SearchPayerAttachments from './PayerAttachmentSearch';
import HistoricalTransactions from '../Routes/HistoricalTransactions';

const Layout = () => {
  return (
    <>
      <AuthContainer>
        <Header />
        <Footer />
      </AuthContainer>
    </>
  );
};

const router = [
  {
    element: <Layout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: '/',
        element: (
          <PrivateRoute>
            <Welcome />
          </PrivateRoute>
        ),
      },
      {
        path: '/logout',
        element: <Logout />,
      },
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/redirect',
        element: <PageRedirect />,
      },
      {
        path: '/unauthorized',
        element: <Unauthorized />,
      },
      {
        path: Navigation.Error,
        element: <Error />,
      },
      {
        path: '/claims',
        element: (
          <PrivateRoute>
            <Claims />
          </PrivateRoute>
        ),
      },
      {
        path: '/account-selection',
        element: (
          <PrivateRoute>
            <AccountSelection />
          </PrivateRoute>
        ),
      },
      {
        path: '/historical-transactions',
        element: (
          <PrivateRoute>
            <HistoricalTransactions />
          </PrivateRoute>
        ),
      },
      {
        path: '/era-search',
        element: (
          <PrivateRoute>
            <EraSearch />
          </PrivateRoute>
        ),
      },
      {
        path: '/payer-attachments',
        element: (
          <PrivateRoute>
            <SearchPayerAttachments />
          </PrivateRoute>
        ),
      },
      {
        path: '/privacy-policy',
        element: <PrivacyPolicy />,
      },
      {
        path: '/terms-of-use',
        element: <TermsOfUse />,
      },
      {
        path: '/health',
        element: <Health />,
      },
      {
        path: Navigation.Onboarding,
        element: (
          <PrivateRoute>
            <Onboarding />
          </PrivateRoute>
        ),
      },
      {
        path: '*',
        element: (
          <PrivateRoute>
            <NotFound />
          </PrivateRoute>
        ),
      },
    ],
  },
];

export default router;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IInitialStateLegalEntitySlice, ILegalEntity } from './interface';
import { IPagingOptions } from 'allOtherInterfaces/pagingOptionInterface';

const initialState = {
  pagingOptions: undefined,
  totalCount: 0,
  resultCount: 0,
  legalentityData: [],
} as IInitialStateLegalEntitySlice;

export const legalentitySlice = createSlice({
  name: 'legalentitySlice',
  initialState,
  reducers: {
    setLegalEntityData: (state, action: PayloadAction<ILegalEntity[]>) => {
      state.legalentityData = action.payload;
    },
    setLegalEntityPagingOptions: (
      state,
      action: PayloadAction<IPagingOptions>
    ) => {
      state.pagingOptions = action.payload;
    },
    setLegalEntityDataCount: (
      state,
      action: PayloadAction<{ totalCount: number; resultCount: number }>
    ) => {
      state.totalCount = action.payload.totalCount;
      state.resultCount = action.payload.resultCount;
    },
  },
});

export const {
  setLegalEntityData,
  setLegalEntityPagingOptions,
  setLegalEntityDataCount,
} = legalentitySlice.actions;

export default legalentitySlice.reducer;

import React, { memo } from 'react';
import Grid from '@uicl/ui-core/dist/Grid';
import { IEligibilityDeductiblesResponse } from '../../../api/HistoricalTransactionDetailView/interfaces';
import { mockDeductiblesResponse } from '../../../api/HistoricalTransactionDetailView/mockResponse';
import { historicalDeductiblesTableColumns } from './HistoricalGridDetailsTableColumns';

interface IProps {
    historicalDeductiblesResult: IEligibilityDeductiblesResponse;
}

const HistoricalDeductiblesDetailData: React.FC<IProps> = () => {
  const handleMapping = () =>
    mockDeductiblesResponse.deductibles.map((item) => {
      return {
        deductible: item.deductible,
        amountInfo: item.amountInfo,
        amount: item.amount,
    };
    });

  return (
      <div style={{ marginTop: 20, paddingBottom: 50, paddingLeft:50, paddingRight:20 }}>
        <Grid
          dataTestId="eligibility-deductiblesdetail-grid"
          domID="eligibility-deductibles-grid"
          isConfigurable={false}
          records={handleMapping()}
          selectAllCheckboxDomID="select-all-test-id"
          selectionKey="text"
          supportSelection={false}
          columns={historicalDeductiblesTableColumns}
          maxHeight="500px"  
          isFixedHeader={true}
        />
      </div>
  );
};
export default memo(HistoricalDeductiblesDetailData);
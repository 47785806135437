import React from 'react';
import Text from '@uicl/ui-core/dist/Text';
import { InsuredStyledDiv, HistGridDiv, HistGridCenterDiv, HistFirstGridDiv, HistFirstCenterGridDiv } from './styles';
import { CollapsePanel, CollapsePanelDetails, CollapsePanelTitle } from '@uicl/ui-core/dist/CollapsePanel';
import { IEligibilityOverviewResponse} from '../../../api/HistoricalTransactionDetailView/interfaces';

interface IProps {
  eligibilityOverview: IEligibilityOverviewResponse;
}

const CoverageInformationComponent: React.FC<IProps> = ({ eligibilityOverview }) => {
  return (
    <div data-testid="coverageInfoContainer">
      <CollapsePanel dataTestId="test-collapsePanel_payer">
        <CollapsePanelTitle aria-controls="collapse-details" domID="collapse-title">
          Payer
        </CollapsePanelTitle>
        <CollapsePanelDetails>
          <InsuredStyledDiv>
            <HistFirstGridDiv>
              <Text color="secondary" variant="small">
                Payer Name
              </Text>
              <Text variant="smallBold">{eligibilityOverview.payer?.payerName}</Text>
            </HistFirstGridDiv>
            <HistFirstCenterGridDiv>
              <Text color="secondary" variant="small">
                Payer ID
              </Text>
              <Text variant="smallBold">{eligibilityOverview.payer?.payerId}</Text>
            </HistFirstCenterGridDiv>
          </InsuredStyledDiv>
        </CollapsePanelDetails>
      </CollapsePanel>
      <CollapsePanel dataTestId="test-collapsePanel_provider">
        <CollapsePanelTitle aria-controls="collapse-details" domID="collapse-title">
          Provider
        </CollapsePanelTitle>
        <CollapsePanelDetails>
          <InsuredStyledDiv>
            <HistGridDiv>
              <Text color="secondary" variant="small">
                Provider Name
              </Text>
              <Text variant="smallBold">{eligibilityOverview.provider?.providerName}</Text>
            </HistGridDiv>
            <HistGridDiv>
              <HistGridCenterDiv>
                <Text color="secondary" variant="small">
                  NPI
                </Text>
                <Text variant="smallBold">{eligibilityOverview.provider?.renderingNpi}</Text>
              </HistGridCenterDiv>
            </HistGridDiv>
            <HistGridDiv>
              <HistGridCenterDiv>
                <Text color="secondary" variant="small">
                  Tax ID
                </Text>
                <Text variant="smallBold">{eligibilityOverview.provider?.taxId}</Text>
              </HistGridCenterDiv>
            </HistGridDiv>
          </InsuredStyledDiv>
          <InsuredStyledDiv>
            <HistGridDiv>
              <Text color="secondary" variant="small">
                Address
              </Text>
              <Text variant="smallBold">{eligibilityOverview.provider?.address}</Text>
            </HistGridDiv>
          </InsuredStyledDiv>
        </CollapsePanelDetails>
      </CollapsePanel>
      <CollapsePanel dataTestId="test-collapsePanel_subscriber">
        <CollapsePanelTitle aria-controls="collapse-details" domID="collapse-title">
          Subscriber
        </CollapsePanelTitle>
        <CollapsePanelDetails>
          <InsuredStyledDiv>
            <HistGridDiv>
              <Text color="secondary" variant="small">
                Insured Name
              </Text>
              <Text variant="smallBold">{eligibilityOverview.subscriber?.insuredName}</Text>
            </HistGridDiv>
            <HistGridDiv>
              <HistGridCenterDiv>
                <Text color="secondary" variant="small">
                  Insured ID
                </Text>
                <Text variant="smallBold">{eligibilityOverview.subscriber?.insuredId}</Text>
              </HistGridCenterDiv>
            </HistGridDiv>
            <HistGridDiv>
              <HistGridCenterDiv>
                <Text color="secondary" variant="small">
                  Date of Birth
                </Text>
                <Text variant="smallBold">{eligibilityOverview.subscriber?.dateOfBirth}</Text>
              </HistGridCenterDiv>
            </HistGridDiv>
          </InsuredStyledDiv>
          <InsuredStyledDiv>
            <HistGridDiv>
              <Text color="secondary" variant="small">
                Group Number
              </Text>
              <Text variant="smallBold">{eligibilityOverview.subscriber?.groupNumber}</Text>
            </HistGridDiv>
            <HistGridDiv>
              <HistGridCenterDiv>
                <Text color="secondary" variant="small">
                  Group Name
                </Text>
                <Text variant="smallBold">{eligibilityOverview.subscriber?.groupName}</Text>
              </HistGridCenterDiv>
            </HistGridDiv>
            <HistGridDiv>
              <HistGridCenterDiv>
                <Text color="secondary" variant="small">
                  Gender
                </Text>
                <Text variant="smallBold">{eligibilityOverview.subscriber?.gender}</Text>
              </HistGridCenterDiv>
            </HistGridDiv>
          </InsuredStyledDiv>
          <InsuredStyledDiv>
            <HistGridDiv>
              <Text color="secondary" variant="small">
                Address
              </Text>
              <Text variant="smallBold">{eligibilityOverview.subscriber?.address}</Text>
            </HistGridDiv>
          </InsuredStyledDiv>
        </CollapsePanelDetails>
      </CollapsePanel>
      <CollapsePanel dataTestId="test-collapsePanel_dependent">
        <CollapsePanelTitle aria-controls="collapse-details" domID="collapse-title">
          Dependent
        </CollapsePanelTitle>
        <CollapsePanelDetails>
          <InsuredStyledDiv>
            <HistGridDiv>
              <Text color="secondary" variant="small">
                Patient Name
              </Text>
              <Text variant="smallBold">{eligibilityOverview.dependent?.patientName}</Text>
            </HistGridDiv>
            <HistGridDiv>
              <HistGridCenterDiv>
                <Text color="secondary" variant="small">
                  Relationship
                </Text>
                <Text variant="smallBold">{eligibilityOverview.dependent?.relationship}</Text>
              </HistGridCenterDiv>
            </HistGridDiv>
            <HistGridDiv>
              <HistGridCenterDiv>
                <Text color="secondary" variant="small">
                  Date of Birth
                </Text>
                <Text variant="smallBold">{eligibilityOverview.dependent?.dateOfBirth}</Text>
              </HistGridCenterDiv>
            </HistGridDiv>
          </InsuredStyledDiv>
          <InsuredStyledDiv>
            <HistGridDiv>
              <Text color="secondary" variant="small">
                Group Number
              </Text>
              <Text variant="smallBold">{eligibilityOverview.dependent?.groupNumber}</Text>
            </HistGridDiv>
            <HistGridDiv>
              <HistGridCenterDiv>
                <Text color="secondary" variant="small">
                  Group Name
                </Text>
                <Text variant="smallBold">{eligibilityOverview.dependent?.groupName}</Text>
              </HistGridCenterDiv>
            </HistGridDiv>
            <HistGridDiv>
              <HistGridCenterDiv>
                <Text color="secondary" variant="small">
                  Gender
                </Text>
                <Text variant="smallBold">{eligibilityOverview.dependent?.gender}</Text>
              </HistGridCenterDiv>
            </HistGridDiv>
          </InsuredStyledDiv>
        </CollapsePanelDetails>
      </CollapsePanel>
      <CollapsePanel dataTestId="test-collapsePanel_coveragetype">
        <CollapsePanelTitle aria-controls="collapse-details" domID="collapse-title">
          Coverage Type
        </CollapsePanelTitle>
        <CollapsePanelDetails>
          <InsuredStyledDiv>
            <HistGridDiv>
              <Text color="secondary" variant="small">
                Dental
              </Text>
              <Text variant="smallBold">{eligibilityOverview.coveragetype}</Text>
            </HistGridDiv>
          </InsuredStyledDiv>
        </CollapsePanelDetails>
      </CollapsePanel>
      <CollapsePanel dataTestId="test-collapsePanel_dependent">
        <CollapsePanelTitle aria-controls="collapse-details" domID="collapse-title">
          Coverage Dates
        </CollapsePanelTitle>
        <CollapsePanelDetails>
          <InsuredStyledDiv>
            <HistGridDiv>
              <Text color="secondary" variant="small">
                Subscriber Coverage Dates
              </Text>
            </HistGridDiv>
          </InsuredStyledDiv>
          <InsuredStyledDiv>
            <HistGridDiv>
              <Text color="secondary" variant="small">
                Plan Begin Date
              </Text>
              <Text variant="smallBold">
                {eligibilityOverview.CoverageDates.subscriberCoverageDates[0].planBeginDate}
              </Text>
            </HistGridDiv>
            <HistGridDiv>
              <HistGridCenterDiv>
                <Text color="secondary" variant="small">
                  Plan End Date
                </Text>
                <Text variant="smallBold">
                  {eligibilityOverview.CoverageDates.subscriberCoverageDates[0].planEndDate}
                </Text>
              </HistGridCenterDiv>
            </HistGridDiv>
            <HistGridDiv>
              <HistGridCenterDiv>
                <Text color="secondary" variant="small">
                  Policy Effective
                </Text>
                <Text variant="smallBold">
                  {eligibilityOverview.CoverageDates.subscriberCoverageDates[0].policyEffective}
                </Text>
              </HistGridCenterDiv>
            </HistGridDiv>
          </InsuredStyledDiv>
          <InsuredStyledDiv>
            <HistGridDiv>
              <Text color="secondary" variant="small">
                Dependent Coverage Dates
              </Text>
            </HistGridDiv>
          </InsuredStyledDiv>
          <InsuredStyledDiv>
            <HistGridDiv>
              <Text color="secondary" variant="small">
                Plan Begin Date
              </Text>
              <Text variant="smallBold">
                {eligibilityOverview.CoverageDates.dependentCoverageDates[0].planBeginDate}
              </Text>
            </HistGridDiv>
            <HistGridDiv>
              <HistGridCenterDiv>
                <Text color="secondary" variant="small">
                  Plan End Date
                </Text>
                <Text variant="smallBold">
                  {eligibilityOverview.CoverageDates.dependentCoverageDates[0].planEndDate}
                </Text>
              </HistGridCenterDiv>
            </HistGridDiv>
            <HistGridDiv>
              <HistGridCenterDiv>
                <Text color="secondary" variant="small">
                  Policy Effective
                </Text>
                <Text variant="smallBold">
                  {eligibilityOverview.CoverageDates.dependentCoverageDates[0].policyEffective}
                </Text>
              </HistGridCenterDiv>
            </HistGridDiv>
          </InsuredStyledDiv>
        </CollapsePanelDetails>
      </CollapsePanel>
    </div>
  );
};

export default CoverageInformationComponent;

import React, { Dispatch, memo, useState, useEffect } from 'react';
import Grid from '@uicl/ui-core/dist/Grid';
import { historicalSearchTableColumns } from './HistoricalTransactionsTableColumns';
import {
  ESortingDirectionTransactionsSearch,
  IHistoricalTransactionsSerchResults,
  TSortingFieldTransactionsSearch,
} from '../../api/HistoricalTransactions/interfaces';
import HistoricalTransactionDetailDrawer from './HistoricalTransactionDetailDrawer';
import { useAppDispatch } from '../../reduxStore/reduxHooks';
import { setAppError } from '../../reduxStore/authSlice';
import { x12TraceType } from '../../utils/constants';
import { getHistoricalTransactionFileData } from '../../api/HistoricalTransactionDetailView';
import moment from 'moment'
import { IEligibilityOverviewResponse, IEligibilityMaximumsResponse, IEligibilityDeductiblesResponse, IEligibilityPlanProvisionsResponse, IEligibilityCoverageResponse, IEligibilityFrequencyLimitationsResponse, IEligibilityAgeLimitationsResponse} from 'api/HistoricalTransactionDetailView/interfaces';
enum tableSortingDirection {
  SORT_ASCENDING = 'SORT_ASCENDING',
  SORT_DESCENDING = 'SORT_DESCENDING',
}
interface IProps {
  onClickThrough: (e: any, row: any) => void;
  historicalSearchResult: IHistoricalTransactionsSerchResults[];
  openX12Transaction: boolean;
  onCloseDrawer: () => void;
  transactionId: string;
  timeOfTransaction:string;
  setSortingDirection: Dispatch<React.SetStateAction<ESortingDirectionTransactionsSearch>>;
  setSortingField: Dispatch<React.SetStateAction<string>>;
  sortingField: string;
  mockEligibilityDetailResponse : IEligibilityOverviewResponse;
  mockDeductiblesResponse : IEligibilityDeductiblesResponse;
  mockMaximumsResponse : IEligibilityMaximumsResponse;
  mockPlanProvisionsResponse : IEligibilityPlanProvisionsResponse;
  mockCoverageResponse : IEligibilityCoverageResponse;
  mockFrequencyLimitationsResponse : IEligibilityFrequencyLimitationsResponse;
  mockAgeLimitationsResponse : IEligibilityAgeLimitationsResponse;
}
const HistoricalTransactionsaSearchTableData: React.FC<IProps> = ({
  onClickThrough,
  historicalSearchResult,
  openX12Transaction,
  onCloseDrawer,
  transactionId,
  timeOfTransaction,
  setSortingDirection,
  setSortingField,
  sortingField,
  mockEligibilityDetailResponse,
  mockDeductiblesResponse,
  mockMaximumsResponse,
  mockPlanProvisionsResponse,
  mockCoverageResponse,
  mockFrequencyLimitationsResponse,
  mockAgeLimitationsResponse
}) => {
  const dispatch = useAppDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const [tabContent, setTabContent] = useState('');
  const [traceType, setTraceType] = useState('');
 
  useEffect(() => {
    if (openX12Transaction) {
        const firstLoadX12Select = x12TraceType.at(0);
      if (firstLoadX12Select) handleFileData(firstLoadX12Select.value);
    }
  }, [openX12Transaction]);

  const handleFileData = async (traceTypeParam: string) => {
    try {
      setIsFetching(true);
      setTraceType(traceTypeParam);

      const response = await getHistoricalTransactionFileData(transactionId, traceTypeParam, dispatch);
      const base64Conversion = decodeURIComponent(escape(atob(response.fileData)));
      const splitX12Data = base64Conversion.split('~');
      const fileData = splitX12Data.join('~' + '\n');
      setTabContent(fileData);
      setIsFetching(false);
    } catch (err) {
      const error = err as Error;
      dispatch(setAppError({ message: error.message, isOpen: true }));
      setTabContent('');
      setIsFetching(false);
    }
  };

  const handleSave = () => {
    const content = tabContent;
    const blob = new Blob([content], { type: 'text/plain' });

    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${traceType}_${transactionId}_X12.txt`;
    document.body.appendChild(link);
    link.click();
  };

  const handleMapping = () =>
    historicalSearchResult.map((item, index) => {
      return {
        transactionId: item.transactionId,
        patientLastName: item.patientLastName,
        patientFirstName: item.patientFirstName,
        insuredId: item.insuredId,
        payerId: item.payerId,
        payerName: item.payerName,
        errorCode: item.errorCode,
        categoryCode: item.categoryCode,
        category: item.category,
        hippaCode: item.hippaCode,
        timeoftransaction: moment.utc(item.timeOfTransaction).local().format('MM/DD/yyyy hh:mm:ss A'),
        vendorId: item.vendorId,
        userName: item.userName,
        taxId: item.taxId,
        renderingNpi: item.renderingNpi,
        totalseconds: item.totalSeconds + 's',
        payerseconds: item.payerSeconds + 's',
        instance: item.instance,
        payerPayloadID: item.payerPayloadID,
        payerTimeStamp: item.payerTimeStamp,
      };
    });
  const onSort = (column: any, direction: any, records: any) => {
    const sorting = direction.sortingKey.split('|');
    const fieldName = sorting[0];
    const directionName =
      sorting[1] == tableSortingDirection.SORT_ASCENDING
        ? ESortingDirectionTransactionsSearch.ASCENDING
        : ESortingDirectionTransactionsSearch.DESCENDING;

    setSortingDirection(directionName);
    setSortingField(fieldName);
  };
  return (
    <div >
        <Grid
          dataTestId="historical-transaction-grid"
          domID="historical-transaction-grid"
          initialSortingKey={`${sortingField}|SORT_DESCENDING`}
          isConfigurable={false}
          onClickThrough={onClickThrough}
          records={handleMapping()}
          selectAllCheckboxDomID="select-all-test-id"
          selectionKey="text"
          supportSelection={false}
          columns={historicalSearchTableColumns}
          isFixedHeader={true}
          onSortGridColumn={onSort}
          maxHeight='480px'          
        />
      {openX12Transaction && (
        <HistoricalTransactionDetailDrawer
          onCloseDrawer={onCloseDrawer}
          transactionId={transactionId}
          timeOfTransaction={timeOfTransaction}
          handleFileData={handleFileData}
          isFetching={isFetching}
          tabContent={tabContent}
          traceType={traceType}
          onSave={handleSave}
          mockEligibilityDetailResponse={mockEligibilityDetailResponse}
          mockDeductiblesResponse={mockDeductiblesResponse}
          mockMaximumsResponse={mockMaximumsResponse}
          mockPlanProvisionsResponse={mockPlanProvisionsResponse}
          mockCoverageResponse={mockCoverageResponse}
          mockFrequencyLimitationsResponse={mockFrequencyLimitationsResponse}
          mockAgeLimitationsResponse={mockAgeLimitationsResponse}
        />
      )}
    </div>
  );
};
export default memo(HistoricalTransactionsaSearchTableData);

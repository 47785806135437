import React from 'react';
import LoadingIndicator from '@uicl/ui-core/dist/LoadingIndicator';
import './Loader.scss';

interface LoaderProps {
  className?: string;
}

const Loader = (props: LoaderProps) => (
  <div
    className={'loading-spinner ' + props.className}
    data-testid="loading-indicator-testid"
  >
    <LoadingIndicator />
  </div>
);

export default Loader;

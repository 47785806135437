import {
  IAttachmentDetailResponse,
  IAttachmentFileResponse,
  IPayerAttachmentsSearchRequest,
  IPayerAttachmentsSearchResponse,
} from './interface';
import { apiInstance } from '../apiInstance';

export const searchPayerAttachments = (
  requestBody: IPayerAttachmentsSearchRequest,
  dispatch?: any
): Promise<IPayerAttachmentsSearchResponse> => {
  return new Promise((resolve, reject) => {
    apiInstance(dispatch)
      .post(`/attachment/search`, requestBody)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAttachmentDetails = (attachmentId: string, dispatch?: any): Promise<IAttachmentDetailResponse> => {
  return new Promise((resolve, reject) => {
    apiInstance(dispatch)
      .get(`attachment/${attachmentId}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getFileAttachment = async (
  attachmentId: string,
  attachmentFileId: string,
  metaOnly: boolean,
  dispatch?: any
): Promise<IAttachmentFileResponse> => {
  return new Promise((resolve, reject) => {
    apiInstance(dispatch)
      .get(`attachment/${attachmentId}/files/${attachmentFileId}`, {
        params: { metaOnly: metaOnly },
      })
      .then((r) => {
        resolve(r.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

import React, { useEffect } from 'react';
import {
  IAttachmentDetailResponse,
  IAttachmentFileResponse,
  IPayerAttachmentsSearchResults,
} from '../../../../api/payerAttachments/interface';
import { useAppDispatch } from '../../../../reduxStore/reduxHooks';
import LoadingPage from '../../../../Routes/LoadingPage';
import { getAttachmentDetails, getFileAttachment } from '../../../../api/payerAttachments';
import AttachmentDetailContainer from './AttachmentDetailContainer';
import { setAppError } from '../../../../reduxStore/authSlice';
import Drawer from '../../../Drawer';
import IconButton from '@uicl/ui-core/dist/IconButton';
import Close from '@uicl/ui-core/dist/Close';

interface IProps {
  closeDrawer: () => void;
  attachmentSearchResult: IPayerAttachmentsSearchResults;
}

// TODO this component should eventually become a route that fetches the data based on URL id
//  need to take a look at the attachmentSearchResult fields referenced with the API Details
const PayerAttachmentDetail: React.FC<IProps> = ({ closeDrawer, attachmentSearchResult }) => {
  const dispatch = useAppDispatch();

  const [attachmentDetails, setAttachmentDetails] = React.useState<IAttachmentDetailResponse>(
    {} as IAttachmentDetailResponse
  );
  const [attachmentFiles, setAttachmentFiles] = React.useState<IAttachmentFileResponse[]>(
    [] as IAttachmentFileResponse[]
  );
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoadingDetail, setIsLoadingDetail] = React.useState(true);
  const [isLoadingImages, setIsLoadingImages] = React.useState(true);

  // combine the loadings from the API calls
  useEffect(() => {
    if (!isLoadingDetail && !isLoadingImages) setIsLoading(false);
  }, [isLoadingDetail, isLoadingImages, isLoading]);

  // get the attachment details (everything besides the image)
  useEffect(() => {
    const handleGetAttachmentDetail = async () => {
      if (attachmentSearchResult?.attachmentId) {
        try {
          const response = await getAttachmentDetails(attachmentSearchResult.attachmentId, dispatch);
          setAttachmentDetails(response);
        } catch (e) {
          const error = e as Error;
          dispatch(setAppError({ message: error.message, isOpen: true }));
        } finally {
          setIsLoadingDetail(false);
        }
      }
    };

    handleGetAttachmentDetail();
  }, [attachmentSearchResult]);

  // get the attachment images
  useEffect(() => {
    const getFileAttachments = async (attachmentId: string, attachmentFileId: string) => {
      if (attachmentId && attachmentFileId) {
        try {
          const attachmentResponse = await getFileAttachment(attachmentId, attachmentFileId, false, dispatch);
          setAttachmentFiles([...attachmentFiles, attachmentResponse]);
        } catch (e) {
          const error = e as Error;
          dispatch(setAppError({ message: error.message, isOpen: true }));
        } finally {
          setIsLoadingImages(false);
        }
      }
      setIsLoadingImages(false);
      return undefined;
    };

    const attachmentId = attachmentSearchResult?.attachmentId;
    attachmentDetails?.attachmentFileOverview?.fileOverviews.map((attachment) => {
      if (attachmentId && attachment?.attachmentFileId)
        void getFileAttachments(attachmentId, attachment.attachmentFileId);
    });
    setIsLoadingImages(false);
  }, [isLoadingDetail]);

  // pass state to container, handle showing/hiding the container once the data is ready
  return (
    <>
      <div data-testid="unsubmitted-claim-drawer">
        <Drawer percentageView={true} containerWidth={'90vw'}>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <IconButton dataTestId="close-drawer-button" icon={Close} size="medium" onClick={closeDrawer} />
          </div>
          {isLoading ? (
            <LoadingPage />
          ) : (
            <AttachmentDetailContainer
              closeDrawer={closeDrawer}
              attachmentDetails={attachmentDetails}
              attachmentDetailImages={attachmentFiles}
              attachmentSearchResult={attachmentSearchResult}
            />
          )}
        </Drawer>
      </div>
    </>
  );
};

export default PayerAttachmentDetail;

import jwt_decode from 'jwt-decode';

export function isValidToken(token: any) {
  const decodeObject: any = jwt_decode(token);
  if (Date.now() >= decodeObject.exp * 1000) {
    return false;
  } else {
    return true;
  }
}

export function stringToBoolean(value: string) {
  if (value) {
    if (!isNaN(parseInt(value))) {
      return value === '1';
    } else {
      return value.toLocaleLowerCase() === 'true';
    }
  } else {
    return false;
  }
}

export function stringToNumber(value: string) {
  return parseInt(value);
}

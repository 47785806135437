import { IAddress } from '../allOtherInterfaces/addressInterface';
import { getFromSessionStorage } from './sessionStorage';
import { EGender } from './enums';
import { IAttachedFile } from '../api/attachments/interface';
import { ISelectedAccount } from '../Routes/AccountSelection';
import { SelectDropdownValue } from '@uicl/ui-core/types/Atoms/SelectDropdown';
import styled from 'styled-components';
import { REACT_APP_STAGE } from './config';

export const title = {
  Email: 'email',
  Code: 'code',
  NewCode: 'new-code',
  NewPwrdCode: 'new-password',
  NewPass: 'newpass',
  ConfirmPwrd: 'confirmpassword',
  Input: 'Input',
  BILLINGNPI: 'Billing NPI',
  TYPEOFATTACHMENTLABEL: 'Type of Attachment',
  Title: 'Dental Converge',
  BiilinTitle: 'Dental Converge: Billing',
  AccountTitle: 'Dental Converge: Edit Account Details',
  Dashboard: 'Dental Converge: Dashboard',
  Dental: 'Dental',
  Connect: 'Converge',
  editUser: 'Edit User',
  Claims: 'Dental Converge: Claims',
  DirectClaims: 'Dental Converge: Direct Claim Entry',
  Claiming: 'Claiming',
  Claimss: 'Claims',
  changepwrd: 'Dental Converge: Change Password',
  ManageUser: 'Dental Converge: Manage Users',
  claimFileSubmission: 'Dental Converge: Claim File Submission',
  PracticeSetup: 'Dental Converge: Manage Practice Setup',
  EligibilityTitle: 'Dental Converge: Eligibility Inquiry',
  SELECTPAYER: 'Select Payer',
  AttachmentID: 'Attachment ID',
  ClaimControlNumber: 'Claim Control Number',
  InsuredID: 'Insured ID',
  ServiceStartDate: 'Service Start Date',
  ServiceEndDate: 'Service End Date',
  SubHeaderTitle: 'Dental Converge: Provider Details',
  CredentialTitle: 'Dental Converge: Credentialing Overview',
  DentalBenefitAdvisorTitle: 'Dental Converge | Dental Benefits Advisor',
  EFTDetails: 'EFT Details',
  PayerIdentification: 'Payer Identification',
  PayeeIdentification: 'Payee Identification',
  FinanceInformation: 'Financial Information',
  ClaimPaymentInformation: 'Claim Payment Information',
  ServicePaymentInformation: 'Service Payment Information',
  ERADetails: 'ERA Details',
  ProviderAdjustment: 'Provider Adjustments',
  DirectClaimTitle: 'Dental Converge: Direct Claim Entry',
  ManageRulePageTitle: 'Dental Converge: Manage Rule',
  ProviderVerificationTitle: 'Dental Converge for Providers Verification',
  RegisterTitle: 'Dental Converge: Registration',
  DenticalReportTitle: 'Dental Converge: Dentical Reports',
  NebraskaReportTitle: 'Dental Converge: Nebraska Medicaid Refund Report File',
  SearchNebraskaReportFileTitle: 'Dental Converge: Nebraska Medicaid Refund Reports',
  NEBRASKA: 'Dental Converge: Nebraska Medicaid Refund Reports',
  DENTICAL: 'Dental Converge: Dentical Reports',
  MANAGERULE: 'Dental Converge: Manage Rule',
  CREDENTIALING: 'Dental Converge: Credentialing Overview',
  CANCELCLAIM: 'Are you sure you want to cancel the claim?',
};

export const Navigation = {
  Default: '/',
  Login: '/login',
  Logout: '/logout',
  Dashboard: '/dashboard',
  PracticeSetup: '/practicesetup',
  UserProfile: '/userProfile',
  ClaimsFileSubmission: '/PrintFileAndResubmisson/ClaimsFileSubmission',
  ClaimDownload: '/claimdownload',
  ManageAccount: '/manageaccount',
  DentalBenefitsAdvisor: '/dentalbenefitsadvisor',
  UpgradeDetails: '/UpgradeDetails',
  Claims: '/claims',
  //UnSubmittedClaims: '/unsubmitted-claims',  //US7525027 : Add back as part of this story
  Onboarding: '/onboarding',
  EraSearch: '/era-search',
  PayerAttachments: '/payer-attachments',
  UnAuthorized: '/unauthorized',
  AccountSelection: '/account-selection',
  Error: '/error',
  HistoricalTransactions: '/historical-transactions',
};

export const DisplayText = {
  ReloadThePage: 'Please reload the page',
  PromotionError: 'Promotion Code has been expired on',
  FileUploadCaptionOne: `Provider's W9`,
  FileUploadCaptionTwo: `Government issued photo ID`,
  UploadValidText: `Supports : jpg, jpeg, bmp, GIF, TIFF, <br/> PNG, PDF, TXT, doc, docx <br/>Up to 5 MB`,
  ClaimFileData1: `Drag Your File Here or Browse `,
  SupportFormatFiles: `.jpg, .jpeg, .bmp, .GIF, .TIFF, .PNG, .PDF, .TXT, .doc, .docx Up to 20 MB`,
  CaptureScreen: `Capture Screen Image `,
  DownloadInstall: `Download & Install Software`,
  SubmittingTaxID: `Select a Tax Id to begin submitting enrollment requests. Your Tax Id must be registered before you can enroll for ERAs. If your Tax Id is not available in the drop-down list, you can click the 'Manage TINs/EINs' button to register it.`,
  ProviderFederalTax: `Provider Federal Tax Identification Number (TIN) or Employee Identification Number (EIN)*`,
  EmptyGrigMessage: 'No payer rules were found for the specified procedure code(s).',
  CancelSubscriptionMessage: 'Are you sure you want to cancel this subscription?',
  DeleteUser: 'Are you sure you want to delete this user?',
  ActivateUser: 'Are you sure to activate this user?',
  DeActivateUser: 'Are you sure to deactivate this user?',
  ERAEnrollmentGridHeader: 'Current and Pending ERA Enrollments',
  TaxIdVerificationHeader: 'Tax ID Information',
  EditDetails: 'Edit Details',
  ERA: 'ERA',
  X12: 'x12',
  PayerEnrollment: 'Payer Enrollment',
  ERAEnrollmentHeader: 'ERA Enrollment List',
  TermsConditions: 'Terms & Conditions',
  DownloadPDF: 'Download PDF',
  acceptTerms: '<label>I accept all the <span>Terms & conditions*</span></label>',
  NoPreviewData: 'No Preview Data',
  SupscribercoverageDate: 'Subscriber Coverage Dates',
  DependentCoverageDate: 'Dependent Coverage Dates',
  PlanBeginDate: 'Plan Begin Date',
  PlanEndDate: 'Plan End Date',
  PlanEffective: 'Policy Effective',
  AccountConfirmModal: 'Any changes you make below will not reflect your account information.',
  AccountValidationModal: 'Details are invalid. Please enter valid Subscriber ID and Claim amount',
  WelcomePara1: ` This registration process will put you on a pathway to
  greater dental office efficiency by providing you with
  electronic real time claim status, claim tracking, easy to
  use reports as well as options to access eligibility with
  detailed benefit descriptions, electronic remittance
  advice (ERA) and electronic funds transfer (EFT/ACH).`,
  WelcomePara2: `During the registration process, you will be asked for a
  few pieces of information that will ensure that the
  privacy and security of your dental office and its clients
  is assured. If you would like to view or download the
  Dental Converge Registration Checklist, please click here.
  The checklist will provide you with a single page that you
  can fill out ahead of time to ensure that you complete the
  registration process quickly and easily.`,
  WelcomeHeader: `Welcome to Dental Converge`,
  VideoTitle: `Welcome,`,
  VideoDiscription: `View this video to see the further steps for creating this
  account`,
  LearnMore: 'Learn More',
  VideoError: 'Your browser does not support HTML video..',
  AccountInfoHeader: 'Account Information',
  AccountSubHeader: 'General Details',
  AccountDescription: `Please enter information about the practice or company you
  wish to enroll in Dental Converge. This information will be
  used to send billing statements and notify you of any
  changes to your account.`,
  DentalAdminContent: `Once you create the account as a Dental Converge Admin you will
  be able to create additional sub users.`,
  DentalAdminHeader: 'Dental Converge Admin',
  AccountUsername: `Password to login with above username`,
  PwrdCheckAssistance: `Please check the password policy for assitance`,
  PwrdContain: 'Password should contain',
  PwrdSame: 'Both Password & Confirm password is same',
  PwrdCharacters: 'Minimum 8 chars in length',
  PwrdUpperCase: 'At least 1 Uppercase character A-Z',
  PwrdLower: 'At least 1 Lowercase character a-z',
  PwrdNumer: 'At least 1 Number 0-9',
  PwrdSpecialChar: 'At least 1 Special character from list: ~ ! @ # $ % ^ & * ( ) - _ = + [ ] { } | ; : , . < > / ?',
  CPSNumber: 'CPS Number (Optional)',
  CPSSubHeader: 'If you have received a CPS Number, please enter it here.',
  PracticeInfoHeader: 'Practice Information',
  PracticeInfoSubHeader: 'Your account and login have been succesfully created',
  PracticeDescription: `Please continue onboarding your Dental Converge account by
  completing the Legal Entity information, including Tax ID.
  This information will be used when managing your Practice
  Setup.`,
  VerifyTaxId: 'Verify your Tax ID',
  VerifyTaxDescription: `Before you can use the Tax ID associated with the practice
  information entered, you must verify it by supplying two
  pieces of information from two dental claims that were
  submitted using this Tax ID. We perform this verification to
  ensure that only authorized individuals can view your claims
  and protect your patients personal health information.`,
  VerifyTaxDescription2: `Please supply the Subscriber ID and the total dollar claim
  amount for each of the two dental claims. Pre-treatment
  estimates and resubmitted claims are not accepted for
  validation. The two claims must be claims for two different
  patients where the dates of service falls within the last 30
  days. When selecting a claim for authorization, please choose
  a claim with only one date of service. It is also recommended
  that you do not use claims that were submitted today.`,
  ToAddLegalEntity: `To add a Legal Entity you need to verify your Tax ID`,
  EnterTaxId: `Enter your Tax ID followed by details from two claims
  submitted within the last 30 days using that Tax ID.`,
  Note: `Note: You will only be able to verify a Tax ID that has been
  used to submit claims in the last 30 days.`,
  PartnerCode: 'Partner Code (Optional)',
  PartnerCodeDescription: 'If you have received a Partner Code, please enter it here.',
  SameasAccount: 'Same as Account Information',
  GotoForgotPwrd: 'Go to forgot password and add new password',
  Welcome: 'Welcome',
  AccountInformaion: 'Account Information',
  TaxIdVerification: 'Tax ID Verification',
  Continue: 'Continue',
  CreateAccount: 'Create Account',
  TaxverificationText: `If you don't submit claims electronically or you're a new
  provider yet to submit claims, we can`,
  VerifyTaxhere: 'verify your Tax ID here.',
  ClaimAmount: 'Claim Amount',
  SubscriberId: 'Subscriber ID',
  Claim2: 'Claim 02',
  Claim1: 'Claim 01',
  ChangePwrd: 'Change password ?',
  ForgotPwrd: 'Forgot Password ?',
  Signinus: 'Sign in as :',
  ActiveUser: 'Click to Activate User',
  InActiveUser: 'Click to Inactivate User',
  ResubmitSubmitButton: 'Submit',
  ResubmitHeaderText: 'Claim Edit',
  ClaimEditHeaderText: 'Edit Claim',
  ClaimEditUpdateButton: 'Update',
  ReasonsNotFound: 'No Reasons Found',
  ConfirmationMessageForSubmittingClaim:
    'Procedure code(s) you have selected require an attachment based on payer rules. Would you like to Submit the details?',
  SinglePayerAlterMessageStart: 'The All Payer attachment service is available at $',
  SinglePayerAlterMessageEnd: ' per month. Are you sure you would like to continue with your current selection?',
  AllPayerBundleName: 'Dental Converge SimpleAttach - All Payer',
  SinglePayerBundle: 'Dental Claim Attachments / Single Payer',
  SimpleAttach: 'Simple Attach',
  SimpleAttachments: 'Submit Attachments',
  AttachmentRules: 'Attachment Rules',
  FollowingProcedureCode: `The following procedure code(s) require an attachment
  based on payer rules. Would you like to add one now?`,
  GeneralUploadText: 'General Upload',
  UserCanUploadDocuments: `You can upload other documents apart from attachment
  rules.`,
  AttachmentOverView: 'Attachment Overview',
  EditAttachment: 'Edit Attachment',
  DragFileHereText: 'Drag Your File Here or Browse',
  SupportFileFormatText: `Supports : jpg, jpeg, bmp, GIF, TIFF, PNG, PDF, TXT, doc, docx Up
  to 20 MB`,
  SimpleAttachDeleteText: `Are you sure you want to delete this- record from Attachment`,
  SimpleAttachSupportFile: 'Support File format: jpg, jpeg, bmp, GIF, TIFF, PNG',
  SimpleAttachMaxFileSize: 'Maximum file size: 20 MB',
  AttachmentOverview: 'Attachments Overview',
  ClaimTreatements: 'Claim Treatments',
  DentalConnectBenefitAdvisor: `Dental Converge | Dental Benefits Advisor`,
  Provider: 'Provider',
  Subscriber: 'Subscriber',
  DependentFieldExplanation: `Details in this section are only required if you are inquiring for
  dependents. Otherwise, please leave these fields blank.`,
  Dependent: 'Dependent',
  DependentRequiredExplanation: `Dependent fields marked required * are only needed if you are
  searching for a dependent. If you are not searching for a dependent be
  sure to leave these fields blank.`,
  Procedure: 'Procedures',
  TransactionId: 'Transaction ID',
  DentalConnectProvider: 'Dental Converge | Provider',
  Address: 'Address',
  DateofBirth: 'Date of Birth',
  GroupNumber: 'Group Number',
  PlanNetworkIdentificationNumber: 'Plan Network Identification Number',
  PriorIdentificationNumber: 'Prior Identification Number',
  SSN: 'SSN',
  ResendCode: 'Resend code',
  Gender: 'Gender',
  MemberID: 'Member ID',
  InsuredName: 'Insured Name',
  ProviderId: 'Provider ID',
  TaxId: 'Tax ID',
  Relationship: 'Relationship',
  GroupName: 'Group Name',
  PatientName: 'Patient Name',
  ProviderNPI: 'Provider NPI',
  Dental: 'Dental',
  Eligibility: 'Eligibility',
  EligibilityInquiry: 'Eligibility Inquiry',
  DentalConnectEligibility: 'Dental Converge | Eligibility',
  placeholderText: 'Select....',
  ProcedureOrServiceType: 'Procedure or Service Type',
  EnterTheCombinationOf: 'Enter The Combination Of',
  OrText: 'Or',
  EnterClaimDetails: 'Enter Claim Details',
  DirectClaimEntry: 'Direct Claim Entry',
  Preview: 'Preview',
  forwardSlash: '/',
  EnrollWithDentalClaimText: `Enroll with 'Dental Converge Claiming' to get access to all
  claiming features.`,
  PleaseContactCustomerService: `If the problem persists, please contact your
  customer service representative.`,
  Claiming: 'Claiming',
  EFTSearch: 'EFT Search',
  ERAEFTSerch: 'ERA/EFT Search',
  DownloadServicePayment: 'To download service payment information you need to enroll with ERA',
  PrintPreview: 'Print Preview',
  Download835: 'Download 835',
  Where: 'where',
  ClearAll: 'Clear all',
  Filters: 'Filters',
  DentalBenefitsAdvisor: 'Dental Benefits Advisor',
  AttachmentHistory: 'Attachment History',
  ResubmissionModalText:
    'Do you want to mark this claim as <br/> Resubmitted ? As you fix this claim it will <br/> be submitted as new claim.',
  AncillaryTitle: 'Ancillary Claim / Treatment Information',
  PlaceOfTreatment: '38. Place Of Treatment',
  Enclosure: '39. Enclosure (Y or N)',
  TreatmentOfOrthodontics: '40. Is treatment for Orthodontics ?',
  DateAppliancePlaced: '41. Date of Appliance Placed',
  MonthsOfTreatmentRemaining: '42. Months of Treatment Remaining',
  ReplacementOfProsthesis: '43. Replacement of Prosthesis?',
  DateOfPriorPlacement: '44. Date of Prior Placement',
  TreatmentResultingFrom: '45.Treatment Resulting from',
  DateOfAccident: '46. Date of Accident',
  AutoAccidentState: '47. Auto Accident State',
  AuthorizationParentSignature:
    ' 36. I have been informed of the treatment plan and associated fees. I agree to be responsible for all charges for dental services and materials not paid by my dental benefit plan, unless prohibited by law, or the treating dentist or dental practice has a contractual agreement with my plan prohibiting all or a portion of such charges. To the extend permitted by law, I consent yo your use and disclosure of my protexted health information to carry out payment activities in connection with this claim.',
  AuthorizationSubscriberSignature:
    '37. I hereby authorize and direct payment of the dental benefits otherwise payable to me, directly to the below named dentist or dental entity.',
  Authorization: 'Authorizations',
  DentalBenifitPlan: 'Dental Benefit Plan Information',
  DentalBenifitText: '3.Company / Plan Name, Address, City, State, Zip Code',
  ResultNotFound: 'Result Not Found',
  EditPayer: 'Edit Payer',
  AddInsuranceCarrier: '+ Add Unlisted Insurance Carrier',
  AddUnlistedCarrier: 'Add Un-Listed Insurance',
  BenifitModalHeaderText: 'Only use this option if you are unable to find the insurance company in the search box.',
  BenifitModalSubHeaderText: 'BenifitModalHeaderText',
  FieldMandatory: '*',
  BillingDentistHeader: 'Billing Dentist or Dentist Entity',
  BillingDentistSubHeader:
    '(Leave blank if dentist or dental entity is not submitting claim on behalf of the patient or insured / subscriber)',
  BillingDentistAddressInfo: '48. Name, Address, City, State, Zip Code',
  BillingNPI: '49. NPI',
  BillingLicense: '50. License Number',
  BillingSSN: '51. SSN or TIN',
  BillingPhone: '52. Phone Number',
  BillingAdditionalProviderID: '52a. Additional Provider ID',
  HeaderInformation: 'Header Information',
  TransactionType: '1. Type of Transaction',
  Predetermination: '2. Predetermination / Preauthorization Number',
  OtherCoverageHeader: 'Other Coverage (Mark applicable box and complete items 5-11. If none, leave blank.)',
  OtherCoverageFourthPoint: '4.',
  OtherCoverageFieldHeader: '(If both, complete 5-11 for dental only)',
  OtherCoverageInfo: '5. Name of the Policyholder/ Subscriber in 4# (Last, First, Middle Initial, Suffix)',
  OtherCoverageDate: '6. Date of Birth (MM/DD/YYYY)',
  OtherCoverageGender: '7. Gender',
  SubscriberID: '8. Policyholder/Subscriber ID (Assigned by Plan)',
  PlanNumber: '9. Plan / Group Number ',
  PatientRelation: "10. Patient's Relationship to Person named in #5",
  OtherInsuranceCompanyDetails: '11. Other Insurance Company/Dental Benefit Plan Name, Address, City, State, Zip Code',
  PatientInformation: 'Patient Information',
  PatientRelationship: '18. Relationship to Primary Subscriber',
  ReservedFutureUse: '19. Reserved For Future Use',
  PatientDetails: ' 20. Name (Last, First, Middle, Initial, Suffix), Address, City, State, Zip Code',
  PatientDateOfBirth: '21 . Date of Birth (MM/DD/YYYY)',
  PatientGender: '22. Gender',
  PatientID: '23 .Patient ID/ Account# (Assigned by Dentist)',
  PolicyholderHeader: 'POLICYHOLDER/SUBSCRIBER INFORMATION (Assigned by Plan Named in #3)',
  PolicyholderSubHeader:
    '12. Policyholder/Subscriber Name (Last, First, Middle Initial, Suffix), Address, City, State,Zip Code',
  PolicyDate: '13. Date of Birth (MM/DD/YYYY)',
  PolicyGender: '14. Gender',
  PolicyholderSubscriberID: '15. Policyholder/Subscriber ID (Assigned by Plan)',
  PolicyholderPlanNumber: '16.Plan / Group Number',
  EmployerName: '17. Employer Name',
  RejectedReason: 'Rejection Reason',
  RejectedReasonText: '(Need to resolve these issues, mention below, by clicking on each)',
  RecordOfServicesHeader: 'Record of Services Provided',
  ProcedureDate: '24. Procedure Date',
  AreaOfOralCavity: '25. Area of Oral Cavity',
  ToothSystem: '26. Tooth System',
  ToothNumber: '27. Tooth Number(s) of Letter(s)',
  ToothSurface: '28. Tooth Surface',
  ProcedureCode: '29. Procedure Code',
  Description: '30. Description',
  Fee: '31. Fee',
  CrossText: 'X',
  AddServiceLine: '+ Add a Service Line',
  MissingTeeth: '33. Missing Teeth Information (Place an "X" on each missing tooth)',
  DiagnosisCode: '34. Diagnosis Code List Qualifier',
  OtherFee: '31a. Other Fee(s)',
  PrimaryDiagnosisCode: '34a. Diagnosis Code(s) (Primary diagnosis in "A")',
  Remarks: '35. Remarks',
  TotlaFee: '32. Total Fee',
  TreatingDentistHeader: 'Treating Dentist and Treatment Location information',
  TreatingDentistSignature:
    '53. I hereby certify that the procedures indicated by date are in progress(for procedures that require multiple visits) or have completed and that the fees submitted are the actual fees. I have charged and intend to collect for those procedures.',
  TreatingSignature: 'Signed (Treating Dentist)',
  TreatingNPI: '54. NPI',
  TratingLicense: '55. License Number',
  TreatingInfo: '56. Address, City, State, Zip Code',
  TreatingSpecialityCode: '56a. Provider Speciality Code',
  TreatingPhone: '57. Phone Number',
  TreatingProviderID: '58. Additional Provider Id',
  BeforeSubmitClaimCHCText: ` Before you can submit claims through Change Healthcare
  Dental Converge, you must finish setting up your practice.`,
  DentalConverge: 'Dental Converge: faster & electronically!',
  NewTo: 'New to',
  EnterEmaillAddress: `enter your email address to create your account`,
  GoToPracticeSetup: 'GO TO PRACTICE SETUP',
  GotoDashboard: 'GO TO DASHBOARD',
  ContactAdminSupport: 'Please contact Customer Support (866) 371-9066',
  GoBackManagePracticeSetup: 'Go Back to Manage Practice Setup',
  GoBackDashboard: 'Go Back to Dashboard',
  Loading: 'Loading..',
  NEMedicalFund: 'NE Medicaid Refund',
  NONEMedicalRecord: 'There is no NE Medicaid Refund data available.',
  GoBack: 'Go back',
  NewSearch: 'New Search',
  NebraskaReports: 'Nebraska Reports',
  Reports: 'Reports',
  Services: 'Services',
  ForgotPwrdHeading: 'Forgot Password',
  PwrdOrUnlockYourAccount: 'Enter your username to reset your password or unlock your account.',
  Submit: 'Submit',
  Cancel: 'Cancel',
  CodePwrdOrUnlockYourAccount: 'Enter code to reset your password or unlock your account.',
  attachmentRequired: 'attachmentRequired',
  unmappedDataAttachmentReason: 'unmapped data',
  incorrectTemplateAttachmentReason: 'incorrect templates',
  clickForgotPwrd: 'Click on forgot password Link',
  ChangePaswrd: 'Change Password',
  PwrdSetup: 'Password Setup',
  ClaimCapture: 'Claim Capture',
  SessionExpire: 'Your session is about to expire',
  LogoutSecond: (second: never) => `You will be logged out in ${second} seconds.`,
  Logout: 'Logout',
  ContinueSession: 'Continue Session',
  LetConnect: "Let's Connect",
  ContactMessage:
    "Our team is happy to answer any of your questions and help assist you with the enrollment process. Fill out the form and we'll get in touch with you soon!",
  InvalidFile: 'Invalid file type , please select valid file type !',
  TaxDetailInvalid: 'Details are invalid. Please enter valid Subscriber ID and Claim amount',
  Ok: 'Ok',
  AddLegalEntity: 'Add Legal Entity',
  InNetwork: 'In-Network',
  OutNetwork: 'Out-of-Network',
  AllNetwork: 'All Network',
  Deductible: 'Deductible',
  Maximum: 'Maximum',
  DeductibleApplies: 'Deductible applies',
  Message: 'Message',
  DescriptionColumn: 'Description',
  ShowDetails: 'Show Details',
  Plan: 'Plan',
  Procedure_Column: 'Procedure',
  Restriction: 'Restriction',
  LastVisit: 'Last Visit Date',
  NextVisit: 'Next Visit Date',
  VisitRemaining: 'Visit Remaining',
  UnmappedDataDetected: 'Unmapped Data Detected',
  NoPayerAttachmentsFound: 'No results found. Please update the search criteria and try again.',
};
export const currentErrorOption = [
  {
    dataTestId: 'true_id',
    id: 'trueid',
    label: 'True',
    value: '1',
  },
  {
    dataTestId: 'false-id',
    id: 'falseid',
    label: 'False',
    value: '0',
  },
];
export const currentTransactionsOption = [
  {
    dataTestId: 'nofilter-testid',
    id: 'filter',
    label: 'No filter',
    value: '0',
  },
  {
    dataTestId: 'trans-testid',
    id: 'retrans1d',
    label: 'Last 1 hour',
    value: '1',
  },
  {
    dataTestId: 'onehour-testid',
    id: 'hour2',
    label: 'Last 2 hours',
    value: '2',
  },
  {
    dataTestId: 'twohour-testid',
    id: 'hour3',
    label: 'Last 3 hours',
    value: '3',
  },
  {
    dataTestId: 'threehour-testid',
    id: 'hour4',
    label: 'Last 4 hours',
    value: '4',
  },
  {
    dataTestId: 'fourhour-testid',
    id: 'hour5',
    label: 'Last 5 hours',
    value: '5',
  },
  {
    dataTestId: 'fivehour-testid',
    id: 'hour6',
    label: 'Last 6 hours',
    value: '6',
  },
];

export const DateFormat = {
  DisplayDate: 'MM/DD/YYYY',
  YYYYMMDDDateFormat: 'YYYYMMDD',
  CalculationDate: 'DD/MM/YYYY',
  YearDate: 'YYYY/MM/DD',
  MMDDYYYY: 'MM-DD-YYYY',
  DISPLAYDATEWITHMINUSSYMBOL: 'YYYY-MM-DD',
  HoursMinFormat: ' h:mm A',
  UTCDateFormat: 'YYYY-MM-DDTHH:mm:ss',
  MMDDYYYYNoDelimiters: 'MMDDYYYY',
};

export const CurrencyFormat = '$';

export const AdminRole = 1;

export const WEB_STORAGE_CONFIG = {
  namespace: 'dental-provider-portal',
};

export const usStateCodesArray: string[] = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'VI',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const isFileValid = (acceptedFiles: Array<IAttachedFile>): boolean => {
  return acceptedFiles.length !== 0;
};

export const handleParseAddress = (address: IAddress) => {
  return `${address.line1} ${address.line2 !== undefined ? address.line2 : ''} ${address.city}, ${address.stateCode} ${
    address.zipCode
  }`;
};
export const handleSelectedAccount = (): ISelectedAccount | null => {
  const selectedAccount = getFromSessionStorage('selectedAccount');
  if (selectedAccount) {
    return JSON.parse(selectedAccount);
  }
  return null;
};

export const renderBirthDayOptions = (): SelectDropdownValue[] => {
  const options: SelectDropdownValue[] = [];
  for (let i = 1; i <= 31; i++) {
    options.push({
      dataTestId: `birth-day-dropdown-${i}`,
      id: i.toString(),
      label: i < 10 ? `0${i}` : `${i}`,
      value: i < 10 ? `0${i}` : `${i}`,
    });
  }
  return options;
};

export const renderBirthMonthOptions = () => {
  const options: SelectDropdownValue[] = [];
  for (let i = 1; i <= 12; i++) {
    options.push({
      dataTestId: `birth-month-dropdown-${i}`,
      id: i.toString(),
      label: i < 10 ? `0${i}` : `${i}`,
      value: i < 10 ? `0${i}` : `${i}`,
    });
  }

  return options;
};

export const renderBirthYearOptions = () => {
  const options: SelectDropdownValue[] = [];
  for (let i = 1900; i <= 2021; i++) {
    options.push({
      dataTestId: `birth-year-dropdown-${i}`,
      id: i.toString(),
      label: `${i}`,
      value: `${i}`,
    });
  }
  return options;
};

export const renderGender = () => {
  const genders = [EGender.UNKNOWN, EGender.MALE, EGender.FEMALE];
  const options = [];
  for (let i = 0; i < genders.length; i++) {
    options.push({
      dataTestId: `gender-dropdown-${i}`,
      id: genders[i],
      label: genders[i],
      value: genders[i],
    });
  }
  return options;
};

export const renderUsStateCodeOptions = () => {
  const options = [];

  for (let i = 0; i < usStateCodesArray.length; i++) {
    options.push({
      dataTestId: `us-state-code-dropdown-${i}`,
      id: i,
      label: usStateCodesArray[i],
      value: usStateCodesArray[i],
    });
  }

  return options;
};

export const convertToDollar = (amount: number) => {
  return (amount ?? 0).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

export const handleDocumentBodyScroll = (isScroll: boolean) => {
  if (isScroll) {
    document.body.style.overflow = 'auto';
  } else {
    document.body.style.overflow = 'hidden';
  }
};

export const regex = {
  payerId: '(^$)|^[a-zA-Z0-9]{1,50}$',
  attachmentId: '(^$)|^[a-zA-Z0-9]{1,80}$',
  billingProviderTaxId: '(^$)|^0[0-9]{8}$',
  insuredId: '(^$)|^[a-zA-Z0-9]{2,80}$',
  legalEntityTaxId: '(^$)|^[0-9]{2}-[0-9]{7}$|^[0-9]{3}-[0-9]{2}-[0-9]{4}$|^[0-9]{9}$',
};

// Determine if the environment is production
const isProduction = REACT_APP_STAGE?.toLowerCase() === 'prod';

export const x12TraceType = [
  // Conditionally add the 'Overview' only if not in production, as it is not needed in production during development and testing phases.
  ...(!isProduction ? [{ key: 'Overview', value: 'Overview' }] : []),
  { key: '270 Sender', value: '270-Sender' },
  { key: '270 Sent', value: '270-Sent' },
  { key: '271 Sender', value: '271-Sender' },
  { key: '271 Sent', value: 'X12 Response' },
];
export const FlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const Disclaiamer = ' Disclaimer: This Optum Dental eligibility report is for informational purposes only. The information is derived directly from the payer indicated on the'+
' report and is not to be construed as a guarantee of payment.';
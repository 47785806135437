import { apiInstance } from '../apiInstance';
import { IClaimViewResponse } from './interface';

export const getProcessedClaimDetail = (claimId: string, dispatch?: any): Promise<IClaimViewResponse> => {
  return new Promise((resolve, reject) => {
    apiInstance(dispatch)
      .get(`/claim/processed/${claimId}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

import { apiInstance } from '../apiInstance';
import { IAccountApiResponse } from './interface';

export const getAccountInfo = (offset: number, limit: number, dispatch?: any): Promise<IAccountApiResponse> => {
  return new Promise((resolve, reject) => {
    apiInstance(dispatch)
      .get('/account', { params: { offset, limit } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

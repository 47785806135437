//TODO https://jira.healthcareit.net/browse/DCP2-7022
import React, { useCallback, useEffect, useState } from 'react';
import LoadingPage from '../Routes/LoadingPage';
import { useAppDispatch, useAppSelector } from '../reduxStore/reduxHooks';
import { setAccountDetails, setUser } from '../reduxStore/userSlice';
import { setAppError } from '../reduxStore/authSlice';
import { getUserAccountInfo } from '../api/user';
import { getLegalEntities } from '../api/legalentity';
import { setLegalEntityData } from '../reduxStore/legalentitySlice';
import { handleSelectedAccount } from '../utils/constants';
import { ILegalEntity } from '../reduxStore/legalentitySlice/interface';
import { mapUserSummaryPayload, setUserSummary } from '../reduxStore/userSummarySlice';
import { IUserPayload, UserActionTypes } from '../reduxStore/userSlice/interfaces';
import { Navigation } from '../utils/constants';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import checkIfActionAllowed from '../reduxStore/userSummarySlice/checkIfActionAllowed';

interface IProps {
  children: React.ReactNode;
}

const AuthContainer: React.FC<IProps> = ({ children }) => {
  const { appError } = useAppSelector((state) => state.authSlice);
  const { account } = useAppSelector((state) => state.userSlice);
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAuth();

  const [isLoader, setLoader] = useState(false);
  const navigate = useNavigate();

  const handleGetLegalEntities = useCallback(
    async (accountId: string) => {
      let morePages = true;
      let legalEntities: ILegalEntity[] = [];
      let offset = 0;

      while (morePages) {
        const response = await getLegalEntities(accountId, offset, 50, dispatch);
        legalEntities = legalEntities.concat(response.results);
        morePages = response.pagingOptions.morePages;
        offset = response.pagingOptions.offset;
      }

      dispatch(setLegalEntityData(legalEntities));
    },
    [isAuthenticated]
  );

  const handleFetch = useCallback(async () => {
    try {
      setLoader(true);

      const response = await getUserAccountInfo(dispatch);

      const userSummary = mapUserSummaryPayload(response);
      dispatch(setUserSummary(userSummary));
      const isAdmin = checkIfActionAllowed(UserActionTypes.MANAGEMULTIPLEACCOUNTS, userSummary);

      dispatch(
        setUser({
          email: response.username,
          given_name: response.firstName,
          family_name: response.lastName,
          isAdmin: isAdmin,
        } as IUserPayload)
      );
      const accountId = response.accountId;

      if (isAdmin) {
        const selectedAccount = handleSelectedAccount();

        if (selectedAccount) {
          const combineAccount = {
            ...response,
            adminAccountInfo: selectedAccount,
          };

          await handleGetLegalEntities(combineAccount.adminAccountInfo.adminAccountId);
          dispatch(setAccountDetails(combineAccount));
        }
      } else {
        await handleGetLegalEntities(accountId);
      }
      dispatch(setAccountDetails(response));
      setLoader(false);
    } catch (err) {
      //TODO: https://jira.healthcareit.net/browse/DCP2-7345 proper handling of error in catch block
      const error = err as Error;
      dispatch(setAppError({ message: error.message, isOpen: true }));
      setLoader(false);
      navigate(Navigation.Error);
    }
  }, [account, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      handleFetch();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    setTimeout(() => {
      //TODO: https://jira.healthcareit.net/browse/DCP2-7345 proper handling of error in catch block
      dispatch(setAppError({ message: '', isOpen: false }));
    }, 6000);
  }, [appError]);

  return isLoader ? <LoadingPage /> : <div>{children} </div>;
};

export default AuthContainer;

import React, { SyntheticEvent, useRef, useState } from 'react';
import LoadingPage from '../../../Routes/LoadingPage';
import Drawer from '../../Drawer';
import Text from '@uicl/ui-core/dist/Text';
import { Button } from '@uicl/ui-core/dist';
import { TabContainer } from '@uicl/ui-core/dist';
import { Disclaiamer, x12TraceType } from '../../../utils/constants';
import { useReactToPrint } from 'react-to-print';
import CoverageInformationComponent from './CoverageInformationComponent';
import HistoricalTransactionsGridsComponent from './HistoricalTransactionsGridsComponent';
import { IEligibilityOverviewResponse, IEligibilityMaximumsResponse, IEligibilityDeductiblesResponse, IEligibilityPlanProvisionsResponse, IEligibilityCoverageResponse, IEligibilityFrequencyLimitationsResponse, IEligibilityAgeLimitationsResponse } from '../../../api/HistoricalTransactionDetailView/interfaces';

interface IProps {
  onCloseDrawer: () => void;
  transactionId: string;
  timeOfTransaction: string;
  handleFileData: (traceType: string) => void;
  isFetching: boolean;
  tabContent: string;
  traceType: string;
  onSave: () => void;
  mockEligibilityDetailResponse: IEligibilityOverviewResponse;
  mockDeductiblesResponse: IEligibilityDeductiblesResponse;
  mockMaximumsResponse: IEligibilityMaximumsResponse;
  mockPlanProvisionsResponse: IEligibilityPlanProvisionsResponse;
  mockCoverageResponse: IEligibilityCoverageResponse;
  mockFrequencyLimitationsResponse: IEligibilityFrequencyLimitationsResponse;
  mockAgeLimitationsResponse: IEligibilityAgeLimitationsResponse;
}

const HistoricalTransactionDetailDrawer: React.FC<IProps> = ({
  onCloseDrawer,
  transactionId,
  timeOfTransaction,
  handleFileData,
  isFetching,
  tabContent,
  traceType,
  onSave,
  mockEligibilityDetailResponse,
  mockDeductiblesResponse,
  mockMaximumsResponse,
  mockPlanProvisionsResponse,
  mockCoverageResponse,
  mockFrequencyLimitationsResponse,
  mockAgeLimitationsResponse  
}) => {
  const componentRef = useRef<any>();
  const [tabIndex, setTabIndex] = useState(0);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleOnChange = (e: SyntheticEvent, state: any) => {
    const selectedTab = x12TraceType.at(state.tabIndex);
    if (selectedTab) {
      setTabIndex(state.tabIndex);
      handleFileData(selectedTab.value);
    }
  };

  const renderButtonSection = () => {
    return (
      <div className="no-print" style={{ display: 'flex' }}>
        <div style={{ marginRight: 10 }}>
          <Button onClick={handlePrint} buttonType="emphasized" dataTestId="print-btn">
            Print
          </Button>
        </div>
        {traceType != 'Overview' && (
          <div style={{ marginRight: 10 }}>
            <Button onClick={onSave} buttonType="emphasized" dataTestId="save-btn">
              Save
            </Button>
          </div>
        )}
        <Button
          onClick={onCloseDrawer}
          buttonType="emphasized"
          domID="close-historical-transaction-view"
          dataTestId="close-historical-transaction-view"
        >
          Close
        </Button>
      </div>
    );
  };

  const renderHeaderView = () => {
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
          <Text color="secondary" variant="xLarge">
            Transaction ID {transactionId}
          </Text>
          {renderButtonSection()}
        </div>
        <div style={{ justifyContent: 'space-between', marginTop: 10, marginLeft: 5 }}>
          <Text color="secondary" variant="small">
            {timeOfTransaction}
          </Text>
        </div>
      </div>
    );
  };

  const handleMapping = () =>
    x12TraceType.map((item, index) => {
      return {
        domID: 'tab_' + index,
        label: item.key,
        tabContent: (
          <div className="printable-section tab-content-spacing" ref={componentRef} id="printable-section">
            {traceType == 'Overview' && (
              <div>
                <CoverageInformationComponent
                  eligibilityOverview={mockEligibilityDetailResponse}
                ></CoverageInformationComponent>
                <HistoricalTransactionsGridsComponent
                  mockDeductiblesResponse={mockDeductiblesResponse} 
                  mockMaximumsResponse={mockMaximumsResponse}
                  mockPlanProvisionsResponse={mockPlanProvisionsResponse}
                  mockCoverageResponse={mockCoverageResponse}
                  mockFrequencyLimitationsResponse={mockFrequencyLimitationsResponse}
                  mockAgeLimitationsResponse={mockAgeLimitationsResponse}
                ></HistoricalTransactionsGridsComponent>
                </div>
            )}
            {tabContent}
          </div>
        ),
      };
    });

  const renderView = () => {
    return (
      <div>
        <TabContainer
          className="tab_container_class"
          contentClass="tab_container_content_class"
          dataTestId="tab_container_testid"
          domID="tab_container_id"
          initialTab={tabIndex}
          onTabChange={(e, state) => {
            handleOnChange(e, state);
          }}
          tabs={handleMapping()}
        />
      </div>
    );
  };
  const renderfooterView = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
        <Text color="secondary" variant="small">
          {Disclaiamer}
        </Text>
      </div>
    );
  };
  return (
    <Drawer percentageView={true} containerWidth={'60vw'}>
      {isFetching ? (
        <LoadingPage />
      ) : (
        <div>
          {renderHeaderView()}
          {renderView()}
          {renderfooterView()}
        </div>
      )}
    </Drawer>
  );
};

export default HistoricalTransactionDetailDrawer;
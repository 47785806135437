import React from 'react';
import Grid from '@uicl/ui-core/dist/Grid';
import { selectedAccountDetailColumn } from './TableColumns';
import { IAdminAccountDetail } from '../../api/user/interface';
import { ISelectedAccount } from '../../Routes/AccountSelection';

interface IProps {
  selectedAdminAccountDetail: ISelectedAccount;
}

const SelectedAccountDetails: React.FC<IProps> = ({
  selectedAdminAccountDetail,
}) => {
  return (
    <div style={{ marginTop: 5 }}>
      <Grid
        dataTestId="claim-search-grid"
        domID="claim-search-grid"
        isConfigurable={false}
        records={[selectedAdminAccountDetail]}
        selectAllCheckboxDomID="select-all-test-id"
        selectionKey="text"
        supportSelection={false}
        columns={selectedAccountDetailColumn}
        maxHeight="500px"
        isFixedHeader={true}
      />
    </div>
  );
};

export default SelectedAccountDetails;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IAuthTokenPayload, IInitialStateAuthSlice } from './interfaces';
import {
  REACT_APP_IDLE_POPUP_TIME,
  REACT_APP_REFRESH_TOKEN_RENEW_TIME,
} from '../../utils/config';
import jwt_decode from 'jwt-decode';

const defaultState = {
  authToken: undefined,
  authTokenExp: undefined,
  isAuthenticated: undefined,
  refreshTokenRenewMiilliSecond: undefined,
  idleTimeoutMilliSecond: undefined,
  idleTimeStamp: undefined,
  appError: {
    message: '',
    isOpen: false,
  },
};

const initialState = {
  ...defaultState,
} as IInitialStateAuthSlice;

export const authSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setAuthToken: (state, action: PayloadAction<IAuthTokenPayload>) => {
      state.authToken = action.payload.token;

      const decoded: any = jwt_decode(action.payload.token);
      state.authTokenExp = decoded.exp * 1000;

      state.refreshTokenRenewMiilliSecond =
        decoded.exp * 1000 -
        Math.floor(new Date().getTime()) -
        Number(REACT_APP_REFRESH_TOKEN_RENEW_TIME) * 1000;
      if (action.payload.IdleTimout) {
        state.idleTimeoutMilliSecond = Number(REACT_APP_IDLE_POPUP_TIME) * 1000;
        state.idleTimeStamp = new Date().getTime();
      }
      if (action.payload.isAuthenticated) state.isAuthenticated = true;
    },

    setExpiration: (state, action: PayloadAction<IAuthTokenPayload>) => {
      const decoded: any = jwt_decode(action.payload.token);
      state.refreshTokenRenewMiilliSecond =
        decoded.exp * 1000 -
        Math.floor(new Date().getTime()) -
        Number(REACT_APP_REFRESH_TOKEN_RENEW_TIME) * 1000;
      if (action.payload.IdleTimout) {
        state.idleTimeoutMilliSecond = Number(REACT_APP_IDLE_POPUP_TIME) * 1000;
        state.idleTimeStamp = new Date().getTime();
      }
    },

    setIdleTimout: (state) => {
      state.idleTimeoutMilliSecond = Number(REACT_APP_IDLE_POPUP_TIME) * 1000;
      state.idleTimeStamp = new Date().getTime();
    },

    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },

    setAppError: (
      state,
      action: PayloadAction<{ message: string; isOpen: boolean }>
    ) => {
      state.appError = action.payload;
    },
    clearAuthStoredData: (state) => {
      state = defaultState;
    },
  },
});

export const {
  setAuthToken,
  setExpiration,
  setIdleTimout,
  setAuthenticated,
  clearAuthStoredData,
  setAppError,
} = authSlice.actions;

export default authSlice.reducer;

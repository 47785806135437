import React, { memo } from 'react';
import Grid from '@uicl/ui-core/dist/Grid';
import { IEligibilityMaximumsResponse } from '../../../api/HistoricalTransactionDetailView/interfaces';
import { mockMaximumsResponse} from '../../../api/HistoricalTransactionDetailView/mockResponse';
import { historicalMaximumsTableColumns } from './HistoricalGridDetailsTableColumns';

interface IProps {
    historicalMaximumsResult: IEligibilityMaximumsResponse;
}

const HistoricalMaximumsDetailData: React.FC<IProps> = () => {
  const handleMapping = () =>
    mockMaximumsResponse.maximums.map((item) => {
      return {
        maximum: item.maxmimum,
        amountInfo: item.amountInfo,
        inNetwork: item.inNetwork,
        outOfNetwork: item.outOfNetwork,
    };
    });

  return (
    <div>
      <div style={{ marginTop: 10, paddingBottom: 50, paddingLeft:50, paddingRight:20 }}>
        <Grid
          dataTestId="eligibility-maximumsdetail-grid"
          domID="eligibility-maximums-grid"
          isConfigurable={false}
          records={handleMapping()}
          selectAllCheckboxDomID="select-all-test-id"
          selectionKey="text"
          supportSelection={false}
          columns={historicalMaximumsTableColumns}
          maxHeight="500px"  
          isFixedHeader={true}
        />
      </div>
    </div>
  );
};

export default memo(HistoricalMaximumsDetailData);
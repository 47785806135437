export const claimSearchColumns = new Set([
  {
    dataName: 'patientName',
    text: 'Patient Name',
    sortable: false,
    isSorted: 0,
  },
  {
    dataName: 'taxId',
    text: 'Tax ID',
    sortable: false,
    isSorted: 0,
  },
  {
    dataName: 'dateOfService',
    text: 'Date Of Service',
    sortable: true,
    isSorted: 0,
  },
  {
    dataName: 'processedOn',
    text: 'Processed On',
    sortable: true,
    isSorted: 0,
  },
  {
    dataName: 'renderingProvider',
    text: 'Rendering Provider',
    sortable: false,
    isSorted: 0,
  },
  {
    dataName: 'amount',
    text: 'Amount',
    sortable: true,
    isSorted: 0,
  },
  {
    dataName: 'payer',
    text: 'Payer',
    sortable: true,
    isSorted: 0,
  },
  {
    dataName: 'status',
    text: 'Status',
    sortable: true,
    isSorted: 0,
    cellType: 'custom',
  },
  {
    dataName: 'attachment',
    text: 'Attachment',
    sortable: false,
    isSorted: 0,
    cellType: 'button',
  },
  {
    dataName: 'reason',
    text: 'Reason',
    sortable: true,
    isSorted: 0,
  },
]);

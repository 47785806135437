import styled from 'styled-components';
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
// @ts-ignore
import termsPdf from './terms.pdf';
import './pdf-style.scss';
import Button from '@uicl/ui-core/dist/Button';
import ButtonGroup from '@uicl/ui-core/dist/ButtonGroup';
import Text from '@uicl/ui-core/dist/Text';
import { DocumentCallback } from 'react-pdf/src/shared/types';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const StyledText = styled(Text)`
  text-align: center;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
`;

const StyledButton = styled(Button)`
  width: 8rem;
`;

const TermsOfUse: React.FC = () => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = (document: DocumentCallback) => {
    setNumPages(document.numPages);
    setPageNumber(1);
  };

  const changePage = (offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  return (
    <>
      <Document className={'pdf-container'} file={termsPdf} onLoadSuccess={onDocumentLoadSuccess} renderMode="canvas">
        <Page
          className={'pdf-container'}
          width={1000}
          scale={1.5}
          pageNumber={pageNumber}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
      </Document>
      <div data-testid="termsofuse-container">
        <StyledText variant={'large'}>
          Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
        </StyledText>
        <StyledButtonGroup size="medium">
          <StyledButton
            buttonType="diminished"
            disabled={pageNumber <= 1}
            onClick={previousPage}
            dataTestId="previous-button"
          >
            Previous
          </StyledButton>
          <StyledButton
            buttonType="diminished"
            disabled={pageNumber >= numPages}
            onClick={nextPage}
            dataTestId="next-button"
          >
            Next
          </StyledButton>
        </StyledButtonGroup>
      </div>
    </>
  );
};
export default TermsOfUse;

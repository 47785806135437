import {
  IHasPayerAttachmentSearchInputErrors,
  IPayerAttachmentsSearchRequest,
} from '../../../api/payerAttachments/interface';
import { InputInterface } from '@uicl/ui-core/types/Atoms/Input';
import { regex } from '../../../utils/constants';

export const searchInputs = (
  searchRequest: IPayerAttachmentsSearchRequest,
  payerAttachmentErrors: IHasPayerAttachmentSearchInputErrors
) => {
  return [
    {
      label: 'Attachment ID',
      domID: 'test-attachmentId',
      initialValue: searchRequest.attachmentId,
      width: '23%',
      marginBottom: 10,
      errorMessage: payerAttachmentErrors.hasErrorPAAttachmentId ? 'Invalid Attachment ID' : undefined,
      validationKey: 'hasErrorPAAttachmentId',
      hasError: payerAttachmentErrors.hasErrorPAAttachmentId,
      initialValidationState: payerAttachmentErrors.hasErrorPAAttachmentId
        ? ('invalid' as InputInterface['initialValidationState'])
        : ('valid' as InputInterface['initialValidationState']),
      minLength: 2,
      maxLength: 80,
      regex: regex.attachmentId,
      key: 'attachmentId',
    },
    {
      label: 'Payer ID',
      domID: 'test-payerId',
      initialValue: searchRequest.payerId,
      width: '23%',
      marginBottom: 10,
      errorMessage: payerAttachmentErrors.hasErrorPAPayerID ? 'Invalid Payer ID' : undefined,
      validationKey: 'hasErrorPAPayerID',
      hasError: payerAttachmentErrors.hasErrorPAPayerID,
      initialValidationState: payerAttachmentErrors.hasErrorPAPayerID
        ? ('invalid' as InputInterface['initialValidationState'])
        : ('valid' as InputInterface['initialValidationState']),
      minLength: 1,
      maxLength: 50,
      regex: regex.payerId,
      key: 'payerId',
    },
    {
      label: 'Billing Provider Tax ID',
      domID: 'test-billingProviderTaxId',
      initialValue: searchRequest.billingProviderTaxId,
      width: '23%',
      marginBottom: 10,
      errorMessage: payerAttachmentErrors.hasErrorPABillingProviderTaxID
        ? 'Invalid Billing Provider Tax ID'
        : undefined,
      validationKey: 'hasErrorPABillingProviderTaxID',
      hasError: payerAttachmentErrors.hasErrorPABillingProviderTaxID,
      initialValidationState: payerAttachmentErrors.hasErrorPABillingProviderTaxID
        ? ('invalid' as InputInterface['initialValidationState'])
        : ('valid' as InputInterface['initialValidationState']),
      minLength: 0,
      maxLength: 9,
      regex: regex.billingProviderTaxId,
      key: 'billingProviderTaxId',
    },
    {
      label: 'Insured ID',
      domID: 'test-insuredId',
      initialValue: searchRequest.insuredId,
      width: '23%',
      marginBottom: 10,
      errorMessage: payerAttachmentErrors.hasErrorPAInsuredID ? 'Invalid Insured ID' : undefined,
      validationKey: 'hasErrorPAInsuredID',
      hasError: payerAttachmentErrors.hasErrorPAInsuredID,
      initialValidationState: payerAttachmentErrors.hasErrorPAInsuredID
        ? ('invalid' as InputInterface['initialValidationState'])
        : ('valid' as InputInterface['initialValidationState']),
      minLength: 2,
      maxLength: 80,
      regex: regex.insuredId,
      key: 'insuredId',
    },
    {
      label: 'Patient Last Name',
      domID: 'test-patientLastName',
      initialValue: searchRequest.patientLastName,
      width: '23%',
      marginBottom: 10,
      validationKey: 'hasErrorPatientLastName',
      hasError: payerAttachmentErrors.payerhasErrorPatientLastName,
      initialValidationState: payerAttachmentErrors.payerhasErrorPatientLastName
        ? ('invalid' as InputInterface['initialValidationState'])
        : ('valid' as InputInterface['initialValidationState']),
      regex: '.*',
      key: 'patientLastName',
    },
  ];
};
